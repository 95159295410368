import $ from 'jquery';
import processInclude from './util';
import cloudinaryWidget from './components/cloudinaryWidgets';

import '~base';

// eslint-disable-next-line no-undef
$(document).ready(() => {
  processInclude(cloudinaryWidget);
});
