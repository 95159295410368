/**
 * This module represents a compatibility layer between the project and base code.
 *
 * The project code must be written using ES6 modules to meet the coding guidelines,
 * however, since its version 6, Babel has removed the compatibility for
 * require-ing ES6 modules, even if they only have a default export:
 * https://github.com/babel/babel/issues/2212
 *
 * This results in all calls to require that resolve to ES6 modules
 * (due to the dependency chain injection) returning an object with a 'default' field,
 * which contains the actual public export of the module.
 *
 * Normally this would be resolved with a Babel plugin:
 * https://www.npmjs.com/package/babel-plugin-add-module-exports
 *
 * However, the plugin requires that the used Babel preset be confiugured to use CommonJS modules.
 * Changing that setting would break the Webpack chunks, so what is done instead is that
 * the base cartridge's modules are transpiled to ES6 before the project code is introduced.
 * This leaves the only remaining hurdle to full compatibility being the processInclude function,
 * since the __webpack_exports__ function returns a Module instance in some cases, which has
 * a default field, containing the module's default export. Attempts to treat it as an
 * iterable object resolve in that 'default' field, which is not usable for this method.
 */

import base from '~base';

/**
 * Intercepts processInclude calls to the base module,
 * and if the passed-in module is an ES6 module with a default export,
 * processes that default export instead of the exported module itself
 *
 * @param  {Object} include The module to process
 */
export default function (include) {
  if (typeof include === 'object' && include.default) {
    base(include.default);
  } else {
    base(include);
  }
}
