export default function () {
  if (document.querySelector('.valid-cookie-warning')) {
    const cookiesAccepted = window.localStorage.getItem('cookiesAccepted');
    const cookieWarningMessageContainer = document.querySelector('.cookie-warning-messaging');

    if (!cookiesAccepted) {
      cookieWarningMessageContainer.classList.add('d-block');
      cookieWarningMessageContainer.classList.remove('d-none');
    } else {
      cookieWarningMessageContainer.classList.add('d-none');
      cookieWarningMessageContainer.classList.remove('d-block');
      window.__rmcp = [1, 2, 3, 4, 5]; // eslint-disable-line no-underscore-dangle
    }

    document.querySelector('.valid-cookie-warning .btn').addEventListener('click', () => {
      window.localStorage.setItem('cookiesAccepted', 'cookiesAccepted');
      window.__rmcp = [1, 2, 3, 4, 5]; // eslint-disable-line no-underscore-dangle
    });
  }
}
