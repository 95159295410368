import $ from 'jquery';
import { getFormValuesObject } from './domutils'; // eslint-disable-line import/named
import {
  fetchOptionsGet, fetchOptionsPost, getFormData, getURLSearchParams,
} from '~base'; // eslint-disable-line import/named

export const CONTENT_TYPES = {
  TEXT: 'TEXT',
  JSON: 'JSON',
};

export const CONTENT_TYPES_MAPS = {
  [CONTENT_TYPES.TEXT]: ['text/html', 'text/plain'],
  [CONTENT_TYPES.JSON]: ['text/json', 'application/json'],
};

const isType = (typeParts, contentType) => typeParts.some(part => contentType.indexOf(part) >= 0);

const getResponseType = (response) => {
  let type = null;

  if (response) {
    const contentTypeHeader = response.headers.get('content-type') || '';
    const contentType = contentTypeHeader.toLowerCase();

    if (isType(CONTENT_TYPES_MAPS[CONTENT_TYPES.JSON], contentType)) {
      type = CONTENT_TYPES.JSON;
    } else if (isType(CONTENT_TYPES_MAPS[CONTENT_TYPES.TEXT], contentType)) {
      type = CONTENT_TYPES.TEXT;
    }
  }

  return type;
};

const getResponseContent = async (response, type) => {
  let content;

  switch (type) {
    case CONTENT_TYPES.JSON:
      content = await response.json();
      break;

    case CONTENT_TYPES.TEXT:
      content = await response.text();
      break;

    default:
      content = null;
  }

  return content;
};

const getUrl = (item) => {
  let url = item.href || item.action || item.dataset.action || item.getAttribute('url');

  if (url && url.indexOf('http') !== 0) {
    url = `${window.location.protocol}//${window.location.host}${url}`;
  }

  return url;
};

const startSpinner = (urlSource) => {
  let spinnerContainer;
  const supportedTags = ['form', 'div', 'span', 'ul'];

  if (urlSource) {
    spinnerContainer = urlSource.closest('[data-spinner-target]');

    if (!spinnerContainer && supportedTags.indexOf(urlSource.tagName.toLowerCase()) >= 0) {
      spinnerContainer = urlSource;
    }
  }

  if (!spinnerContainer) {
    spinnerContainer = document.body;
  }

  $(spinnerContainer).spinner().start();

  return spinnerContainer;
};

export const loadContent = async (trigger, loadUrl = null, additionalData = null, showSpinner = true) => {
  const urlSource = getUrl(trigger) ? trigger : trigger.form;

  let url = loadUrl || getUrl(urlSource);
  const method = (urlSource && (urlSource.method || urlSource.dataset.method)) || 'get';

  let options;
  let formValues = getFormValuesObject(urlSource);

  if (urlSource && urlSource.name && urlSource.tagName && urlSource.tagName.toLowerCase() === 'input') {
    let { value } = urlSource;

    if (urlSource.type === 'radio' || urlSource.type === 'checkbox') {
      value = urlSource.checked;
    }

    formValues = Object.assign({}, { [urlSource.name]: value }, formValues);
  }

  if (typeof additionalData === 'object') {
    formValues = Object.assign({}, additionalData, formValues);
  }

  if (method === 'post') {
    const formData = getFormData(formValues);

    options = fetchOptionsPost(formData);
  } else {
    const urlObject = new URL(url);
    const urlParams = new URLSearchParams(urlObject.search);
    const formUrlParams = getURLSearchParams(formValues);

    // eslint-disable-next-line no-restricted-syntax
    for (const formUrlParamName of formUrlParams.keys()) {
      urlParams.set(formUrlParamName, formUrlParams.get(formUrlParamName));
    }

    urlObject.search = urlParams.toString();
    url = urlObject.toString();

    options = fetchOptionsGet();
  }

  let spinner = null;
  if (showSpinner) {
    spinner = startSpinner(urlSource);
  }

  const response = await fetch(url, options);
  const type = getResponseType(response);
  const content = await getResponseContent(response, type);
  if (showSpinner) {
    $(spinner).spinner().stop();
  }

  return { url, type, content };
};

export * from '~base';
