import $ from 'jquery';
import { isIOS } from '../util/domutils';
import { functions as headerFunctions, EVENTS as HEADER_EVENTS } from './header';

export const EVENTS = {
  FLYOUT_OPEN: 'flyout:opened',
  FLYOUT_CLOSE: 'flyout:closed',
  BACK_SHOW: 'backtotop:show',
  BACK_HIDE: 'backtotop:hide',
};

const SELECTORS = {
  CONTENT: '[class*="__container "]',
};

/**
 * Updates the total height of the minicart container so it can be aligned with the header
 *
 * @param {Element} container The minicart container
 * @param {Number} [pageHeaderHeight=<calculated height>] The height of the header to align against
 */
const recalculateContainer = (container, callback, pageHeaderHeight = headerFunctions.getHeaderHeight()) => {
  container.style.top = `${pageHeaderHeight}px`;
  container.style.height = `calc(100% - ${pageHeaderHeight}px)`;

  if (typeof callback === 'function') {
    callback(container, pageHeaderHeight);
  }
};

/**
 * Ensures that the flyout elements' height gets recalculated when the header resizes
 *
 * @param {Element} container The flyout container
 */
export const initFlyout = (container, callback, toggleClass = 'show') => {
  ['scroll', 'resize'].forEach((evt) => {
    window.addEventListener(evt, () => {
      if (container.classList.contains(toggleClass)) {
        recalculateContainer(container, callback);
      }
    });
  });

  /**
   * Dispatch UPDATE_HEADER in order to update the sticky header
   * listen for UPDATE_HEADER in order to calculate the flyout's dimensions after header is updated
  */
  document.dispatchEvent(new CustomEvent(HEADER_EVENTS.UPDATE_HEADER));

  document.addEventListener(HEADER_EVENTS.UPDATE_HEADER, () => {
    recalculateContainer(container, callback);
  });

  headerFunctions.onHeightChange(recalculateContainer.bind(null, container, callback));

  if (isIOS()) {
    const inputs = container.querySelectorAll('input');
    const content = container.querySelector(SELECTORS.CONTENT);

    inputs.forEach((input) => {
      input.addEventListener('focus', () => {
        setTimeout(() => {
          $(content).scrollTop($(input).offset().top - 20);
        }, 500);
      });
    });
  }

  $(container).on('shown.bs.collapse', () => {
    document.dispatchEvent(new CustomEvent(EVENTS.FLYOUT_OPEN, {
      detail: {
        flyout: container,
        content: container.querySelector(SELECTORS.CONTENT),
      },
    }));
  }).on('hidden.bs.collapse', () => {
    document.dispatchEvent(new CustomEvent(EVENTS.FLYOUT_CLOSE, {
      detail: {
        flyout: container,
        content: container.querySelector(SELECTORS.CONTENT),
      },
    }));
  });

  $(container).on('shown.bs.collapse', () => {
    recalculateContainer(container, callback);
  });

  document.addEventListener(EVENTS.FLYOUT_OPEN, (e) => {
    if (container.classList.contains('show') && e.detail.flyout !== container) {
      $(container).collapse('hide');
    }
    document.dispatchEvent(new CustomEvent(EVENTS.BACK_HIDE));
  });
  document.addEventListener(EVENTS.FLYOUT_CLOSE, () => {
    document.dispatchEvent(new CustomEvent(EVENTS.BACK_SHOW));
  });

  recalculateContainer(container, callback);
};
