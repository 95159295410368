const domParser = new DOMParser();

/**
 * Get JSON data from a data-attribute
 *
 * @param el the DOM element that contains the data attribute
 * @param name the name of the data-attriute (without data- prefix)
 * @param defaultValue the default value
 *
 * @returns the parsed data
 */
export const getJSONData = (el, name, defaultValue) => {
  let result = defaultValue;
  const strData = el.dataset[name];
  if (strData) {
    try {
      const data = JSON.parse(strData);
      if (Array.isArray(data)) {
        result = data;
      } else {
        result = {
          ...defaultValue,
          ...data,
        };
      }
    } catch (ignore) {
      // ignore
    }
  }
  return result;
};

/**
 * Parses a string containing HTML and returns an array of the created elements.
 *
 * @param str the markup text
 *
 * @return the created elements as array
 */
export const parseHTMLString = str => Array.from(domParser.parseFromString(str, 'text/html').body.children);

/**
 * Creates an element from the given string.
 *
 * @param str the markup text
 *
 * @returns the created element
 */
export const createElementFromString = (str) => {
  const parsed = parseHTMLString(str);
  let res;
  if (parsed.length) {
    res = parsed[0]; // eslint-disable-line prefer-destructuring
  }
  return res;
};

/**
 * Appends elements parsed from a string to an element.
 *
 * @param el  the element to append
 * @param str the markup text
 * @param clear clear child elements before (default false)
 */
export const setElementHTML = (el, str, clear = false) => {
  if (clear) {
    el.innerHTML = '';
  }
  parseHTMLString(str).forEach((newEl) => {
    el.appendChild(newEl);
  });
};

/**
 * Event delegation.
 *
 * @param element the DOM element
 * @param eventName the event
 * @param selector selector for sub elements
 * @param fn the event handler function
 */
export const onEvent = (element, eventName, selector, fn) => {
  element.addEventListener(eventName, (e) => {
    let node = e.target || e.srcElement;
    for (; node && node !== element; node = node.parentNode) {
      if (node.matches(selector)) {
        e.delegateTarget = node;
        fn(e);
      }
    }
  });
};

/**
 * Gets the style property as rendered via any means (style sheets, inline, etc) but does *not* compute values
 * @param domNode - the node to get properties for
 * @param properties - Can be a single property to fetch or an array of properties to fetch
 */
export const getFinalStyle = (domNode, properties) => {
  if (!(properties instanceof Array)) {
    properties = [properties]; // eslint-disable-line no-param-reassign
  }

  const parent = domNode.parentNode;
  let originalDisplay;
  if (parent) {
    originalDisplay = parent.style.display;
    parent.style.display = 'none';
  }
  const computedStyles = getComputedStyle(domNode);

  const result = {};
  properties.forEach((prop) => {
    result[prop] = computedStyles[prop];
  });

  if (parent) {
    parent.style.display = originalDisplay;
  }

  return result;
};

/**
 * Get scroll top of document.
 */
export const getScrollTop = () => (document.documentElement ? document.documentElement.scrollTop : 0)
    || document.body.scrollTop;

/**
 * Get the outer width with margin of an element.
 *
 * @param el the DOM element
 */
export const outerWidthWithMargin = (el) => {
  let width = el.offsetWidth;
  const style = getComputedStyle(el);

  width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
  return width;
};

/**
 * Get the outer height with margin of an element.
 *
 * @param el the DOM element
 */
export const outerHeightWithMargin = (el) => {
  let height = el.offsetHeight;
  const style = getComputedStyle(el);

  height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
  return height;
};

/**
 * Get sibling elements of the given element.
 *
 * @param el the DOM element
 * @param filter a filter function
 */
export const getSiblings = (el, filter) => {
  const siblings = [];
  el = el.parentNode.firstChild; // eslint-disable-line no-param-reassign
  do { // eslint-disable-line no-cond-assign
    if (!filter || filter(el)) siblings.push(el);
  } while (el = el.nextSibling); // eslint-disable-line no-param-reassign
  return siblings;
};

/**
 * Adds a class if not set.
 * @param element  the element
 * @param className  the class to add
 */
export const addClassIfNeeded = (element, className) => {
  const addNeeded = element && !element.classList.contains(className);
  if (addNeeded) {
    element.classList.add(className);
  }
  return addNeeded;
};

/**
 * Removes a class if set.
 * @param element  the element
 * @param className  the class to remove
 */
export const removeClassIfNeeded = (element, className) => {
  const removeNeeded = element && element.classList.contains(className);
  if (removeNeeded) {
    element.classList.remove(className);
  }
  return removeNeeded;
};

/**
 * Adds or removes a class if needed.
 * @param element   the element
 * @param className  the class to add or remove
 * @param add  add or remove the class?
 */
export const addOrRemoveClassIfNeeded = (element, className, add) => (
  add ? addClassIfNeeded : removeClassIfNeeded)(element, className);
