import { functions as ajax } from '../components/ajax';

/**
 * When the GTM events are pushed with the value 'beacon' in the field 'transport',
 * the dataLayer library will attempt to use the Beacon API,
 * so events can be tracked despite of the user navigating away form the page;
 *
 * However, it is not supported by all browsers that are still in use:
 * https://caniuse.com/#feat=beacon
 *
 * This function polyfills the sendBeacon method of the window's navigator
 * with a synchrnouse XMLHttpRequest, to guarantee correct tracking
 *
 * Source: Polyfill Service (https://github.com/Financial-Times/polyfill-service)
 */
export const polyfillBeaconAPI = () => {
  window.navigator.sendBeacon = window.navigator.sendBeacon || function (url, data) {
    const xhr = ('XMLHttpRequest' in window) ? new XMLHttpRequest() : new window.ActiveXObject('Microsoft.XMLHTTP');

    xhr.open('POST', url, false);
    xhr.setRequestHeader('Accept', '*/*');

    if (typeof data === 'string') {
      xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
      xhr.responseType = 'text/plain';
    } else if (Object.prototype.toString.call(data) === '[object Blob]') {
      if (data.type) {
        xhr.setRequestHeader('Content-Type', data.type);
      }
    }

    xhr.send(data);

    return true;
  };
};

/**
 * Removes old data from the DataLayer when it needs to be replaced
 */

const replaceDataLayerProperty = (event) => {
  const gtm = window.google_tag_manager[window.googleAnalytics.CONTAINER_ID];

  if (gtm) {
    Object.keys(event.replace).forEach((propKey) => {
      gtm.dataLayer.set(propKey, undefined);
      event[propKey] = event.replace[propKey];
    });
  }

  delete event.replace;

  return event;
};

/**
 * Checks the results of all AJAX requests for JSON,
 * containing analytics events, and pushes them to the data layer, if any
 */
const handleAjaxEvents = (dataLayer, responseText) => {
  let events = [];

  try {
    // Handle JSON repsponses
    const response = JSON.parse(responseText);

    events = response[window.googleAnalytics.ANALYTICS_EVENTS_KEY];
  } catch (ex) {
    if (typeof responseText === 'object') {
      events = responseText[window.googleAnalytics.ANALYTICS_EVENTS_KEY];
    } else if (typeof responseText === 'string') {
      // Handle HTML responses using the GTM events decorator template
      const contentEl = document.createElement('div');

      contentEl.innerHTML = responseText;
      contentEl.querySelectorAll('[name="analytics-events"]').forEach((input) => {
        events = events.concat(JSON.parse(input.value));
      });
    }
  }

  if (events && events.length) {
    for (let i = 0; i < events.length; i++) {
      if (events[i].replace) {
        events[i] = replaceDataLayerProperty(events[i]);
      }

      dataLayer.push(events[i]);
    }
  }

  return responseText;
};

/**
 * Extend the AJAX mechanisms that get used to allow for tracking any events in their responses
 */
export const installAjaxHandlers = () => {
  const dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];

  // Don't attach the listener if GTM or GA are not enabled
  if (!dataLayer) {
    return;
  }

  ajax.onComplete(handleAjaxEvents.bind(null, dataLayer));
};

/**
 * Checks the page for partial remote include templates,
 * containing analytics events, and pushes them to the data layer, if any
 */
export const pushRemoteIncludeEvents = () => {
  let events = [];
  const dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];

  // Don't query the DOM if GTM or GA are not enabled
  if (!dataLayer) {
    return;
  }
  document.querySelectorAll('[name="analytics-events"]').forEach((input) => {
    events = events.concat(JSON.parse(input.value));
  });

  if (events && events.length) {
    for (let i = 0; i < events.length; i++) {
      dataLayer.push(events[i]);
    }
  }
};
