import base from '~base';

const baseInvalid = base.invalid;

base.invalid = () => {
  baseInvalid.call(this);

  document.querySelectorAll('form input, form select').forEach((field) => {
    field.addEventListener('invalid', function (e) {
      e.preventDefault();
      if (!this.validity.valid) {
        const dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];
        if (dataLayer) {
          const event = {
            message: this.closest('.form-group').querySelector('.invalid-feedback').innerText,
          };
          dataLayer.push(event);
        }
      }
    });
  });
};

export default base;
