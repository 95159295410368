import $ from 'jquery';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { EVENTS as FLYOUT_EVENTS } from './flyoutUnderHeader';

const disableBodyScrollModalWrapper = (e) => {
  disableBodyScroll(e.target);
};

const disableBodyScrollFlyoutWrapper = (e) => {
  let target = null;

  if (e.detail) {
    if (e.detail.noScrollLock) {
      return;
    }

    if (e.detail.content) {
      target = e.detail.content; // Generic flyouts
    } else if (e.detail.flyout) {
      target = e.detail.flyout; // Menu flyout
    }
  }

  if (target) {
    disableBodyScroll(target, {
      allowTouchMove: (el) => {
        if (target.contains(el)) {
          let currentEl = el;

          while (currentEl && currentEl !== target) {
            if (currentEl.scrollHeight > currentEl.clientHeight) {
              return true;
            }

            currentEl = currentEl.parentNode;
          }
        }

        return false;
      },
    });
  }
};

export default () => {
  $(document)
    .on('show.bs.modal', disableBodyScrollModalWrapper)
    .on('hidden.bs.modal', clearAllBodyScrollLocks);

  document.addEventListener(FLYOUT_EVENTS.FLYOUT_OPEN, disableBodyScrollFlyoutWrapper);
  document.addEventListener(FLYOUT_EVENTS.FLYOUT_CLOSE, clearAllBodyScrollLocks);
};
