import debounce from '../util/debounce';
import { onEvent } from '../util/domutils'; // eslint-disable-line import/named
import { COLLAPSE_EVENTS } from '../thirdParty/bootstrap/toggleFormElementsStates';
import { NAV_EVENTS } from './clientSideNav';
import { ADDRESS_FORM_EVENTS } from './addressForm';

const SELECTORS = {
  FORMS: 'form',
  PREFILLED: ':-webkit-autofill',
  FORM_SUBMIT: '.btn[type="submit"]',
  FORM_RADIOS: 'form input[type="radio"]',
  FORM_FIELDS: 'form input:not([type="radio"]):not([type="checkbox"]), form select, form textarea',
};

export const FORM_EVENTS = {
  SUBMIT_STATE_CHANGE: 'form:submit-state-chage',
};

const DISABLE_INCOMPLETE_FORMS_SUBMIT = window.beesfra
                                        && window.beesfra.config
                                        && window.beesfra.config.disableIncompleteFormsSubmit;

const handleFormValidity = (form) => {
  const buttons = form.querySelectorAll(SELECTORS.FORM_SUBMIT);

  const fields = form.querySelectorAll(SELECTORS.FORM_FIELDS);
  const requiredFieldsPopulated = Array.from(fields).every(field => field.disabled || !field.required || field.value);

  const radioButtons = form.querySelectorAll(SELECTORS.FORM_RADIOS);
  const radioGroups = Array.from(radioButtons).reduce((groups, radioButton) => {
    groups[radioButton.name] = groups[radioButton.name] || [];
    groups[radioButton.name].push(radioButton);

    return groups;
  }, {});
  const radiosChecked = Object.values(radioGroups).every(group => group.some(radio => radio.disabled || radio.checked));
  const formIncomplete = !requiredFieldsPopulated || !radiosChecked;

  if (DISABLE_INCOMPLETE_FORMS_SUBMIT) {
    buttons.forEach((button) => {
      button.classList.toggle('disabled', formIncomplete);
    });
  }

  document.dispatchEvent(new CustomEvent(FORM_EVENTS.SUBMIT_STATE_CHANGE, {
    detail: {
      form,
      formIncomplete,
    },
  }));
};

/**
 * Visually disableds the submit button of each form which doesn not have
 * all its required fields populated and vice-versa.
 * They don't get actually disabled so they can still be used in case issues
 * occur with the logic, the normal validation should prevent any data issues
 */
const toggleSubmitState = () => {
  ['change', 'keyup'].forEach((event) => {
    onEvent(document, event, SELECTORS.FORM_FIELDS, debounce((e) => {
      handleFormValidity(e.delegateTarget.form || e.delegateTarget);
    }, 300));
  });

  [
    NAV_EVENTS.NAV_BACK,
    NAV_EVENTS.NAV_CONTENT,
    COLLAPSE_EVENTS.FORMS_STATE_CHANGED,
    ADDRESS_FORM_EVENTS.POPULATE,
  ].forEach((evt) => {
    document.addEventListener(evt, () => {
      document.querySelectorAll(SELECTORS.FORMS).forEach(handleFormValidity);
    });
  });

  document.querySelectorAll(SELECTORS.FORMS).forEach(handleFormValidity);
};

/**
 * On WebKit browsers fields that get their values prefilled from e.g. saved passwords
 * don't get their placeholders removed on load. This causes a design issue with the floating labels.
 * The code below iterates and does a focus-blur on each of those fields to force them to behave per design.
 * Only runs on Chrome-like browsers using the vendor-prefixed selector as this seems to be OK on other browsers.
 */
const fixWebkitPrefilledFieldLabels = () => {
  try {
    const affectedForms = [];
    const prefilledFields = Array.from(document.querySelectorAll(SELECTORS.PREFILLED));

    prefilledFields.forEach((field) => {
      field.focus({
        preventScroll: true,
      });
      field.blur();

      if (affectedForms.indexOf(field.form) < 0) {
        affectedForms.push(field.form);
      }
    });

    if (DISABLE_INCOMPLETE_FORMS_SUBMIT) {
      affectedForms.forEach(handleFormValidity);
    }
  } catch (e) {
    // Not on a WebKit-based browser
  }
};

export const initForms = () => {
  toggleSubmitState();
  fixWebkitPrefilledFieldLabels();
};
