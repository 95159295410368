import $ from 'jquery';
import { onEvent } from '../util/domutils'; // eslint-disable-line import/named

const CLASSES = {
  INDICATOR_ACTIVE: 'wishlist__indicator--active',
};
export const EVENTS = {
  WISHLIST_ADD: 'product:addedToWishlist',
  WISHLIST_REMOVE: 'product:removedFromWishlist',
};
const SELECTORS = {
  WISHLIST_BUTTON: '.add-to-wish-list',
  INDICATORS: {
    ADDED: '.wishlist__indicator--added',
    REMOVED: '.wishlist__indicator--removed',
  },
};

const addRemoveFromWishlist = async (delegateTarget, styleClass, toggleAttribute) => {
  const { urlAdd, urlRemove } = delegateTarget.dataset;
  const toggableStyleClass = styleClass || 'active';
  const pid = $(delegateTarget).closest('.product-detail').find('.product-id').html(); // eslint-disable-line
  let optionId = $(delegateTarget).closest('.product-detail').find('.product-option').attr('data-option-id'); // eslint-disable-line
  let optionVal = $(delegateTarget).closest('.product-detail').find('.options-select option:selected').attr('data-value-id'); // eslint-disable-line
  let url = urlAdd || delegateTarget.dataset.href;
  let indicator = document.querySelector(SELECTORS.INDICATORS.ADDED);
  let isAdd = true;
  optionId = optionId || null;
  optionVal = optionVal || null;
  if (!url || !pid) {
    return;
  }
  // init remove operation
  if (delegateTarget.classList.contains(toggableStyleClass)) {
    url = urlRemove;
    indicator = document.querySelector(SELECTORS.INDICATORS.REMOVED);
    isAdd = false;
    $.ajax({ // eslint-disable-line jquery/no-ajax
      url,
      type: 'get',
      dataType: 'json',
      data: {
        pid,
        optionId,
        optionVal,
      },
    });
  } else {
    url = urlAdd || delegateTarget.dataset.href;
    indicator = document.querySelector(SELECTORS.INDICATORS.ADDED);
    isAdd = true;
    $.ajax({ // eslint-disable-line jquery/no-ajax
      url,
      type: 'post',
      dataType: 'json',
      data: {
        pid,
        optionId,
        optionVal,
      },
    });
  }
  indicator.classList.add(CLASSES.INDICATOR_ACTIVE);
  delegateTarget.classList.toggle(toggableStyleClass);
  if (toggleAttribute) {
    delegateTarget.toggleAttribute(toggleAttribute, isAdd);
  }

  if (isAdd) {
    document.dispatchEvent(new CustomEvent(EVENTS.WISHLIST_ADD));
  } else {
    document.dispatchEvent(new CustomEvent(EVENTS.WISHLIST_REMOVE));
  }

  document.addEventListener('click', () => {
    indicator.classList.remove(CLASSES.INDICATOR_ACTIVE);
  }, { once: true });
  setTimeout(function () { // eslint-disable-line prefer-arrow-callback
    indicator.classList.remove(CLASSES.INDICATOR_ACTIVE);
    delegateTarget.blur();
  }, 5000);
};

export const reinitWishlistOnVariantChange = () => {
  document.addEventListener('product:afterAttributeSelect', function () { // eslint-disable-line prefer-arrow-callback
    onEvent(document, 'click', SELECTORS.WISHLIST_BUTTON, async (e) => {
      addRemoveFromWishlist(e.delegateTarget, 'active');
    });
  });
};

export const addToWishlist = () => true;

export default () => {
  onEvent(document, 'click', SELECTORS.WISHLIST_BUTTON, async (e) => {
    addRemoveFromWishlist(e.delegateTarget, 'active');
  });

  onEvent(document, 'click', '.product-tile__wishlist--link', async (e) => {
    addRemoveFromWishlist(e.delegateTarget, 'active');
  });
};
