import { ADDRESS_FORM_EVENTS } from '~base';
import { NAV_EVENTS } from './clientSideNav';
import { EVENTS as CHECKOUT_EVENTS } from '../checkout/checkoutDom';
import { COLLAPSE_EVENTS } from '../thirdParty/bootstrap/toggleFormElementsStates';
import { onEvent, visible } from '../util/domutils';

const IDS = {
  LOOKUP: 'addressLookup',
  CUSTOM_LOOKUP: 'customAddressLookup',
  COUNTRY: 'country',
  ADDRESS_FIELDS: {
    LINE1: 'addressOne',
    LINE2: 'addressTwo',
    LINE3: 'addressThree',
    POSTAL_CODE: 'postalCode',
    CITY: 'city',
    STATE: 'stateCode',
  },
};

const CLASSES = {
  ERROR: 'is-invalid',
};

const SELECTORS = {
  LOOKUP: `#${IDS.LOOKUP}`,
  CUSTOM_LOOKUP: `.${IDS.CUSTOM_LOOKUP}`,
  COUNTRY: `#${IDS.COUNTRY}`,
  ADDRESS_FIELDS: `#${Object.keys(IDS.ADDRESS_FIELDS).map(key => IDS.ADDRESS_FIELDS[key]).join(', #')}`,
  TOGGLE: '.address-lookup__footer',
  SUBMIT_BUTTON: 'button[type="submit"]',
};

const showAddressFields = () => {
  document.querySelectorAll(SELECTORS.ADDRESS_FIELDS).forEach((el) => {
    el.disabled = false;
    el.parentElement.parentElement.classList.remove('d-none');
  });
};

const showAddressFieldsOnReinit = () => {
  setTimeout(() => {
    const fields = Array.from(document.querySelectorAll(SELECTORS.ADDRESS_FIELDS));
    const hasValues = fields.some(field => !!field.value);
    const hasErrors = fields.some(field => field.classList.contains(CLASSES.ERROR));

    if (hasValues || hasErrors) {
      showAddressFields();
    }
  }, 1);
};

const createControl = (lookupField, fields) => {
  const control = new window.pca.Address(fields, {
    key: lookupField.dataset.key,
    suppressAutocomplete: false,
  });
  control.listen('populate', (address) => {
    lookupField.value = address.Label.replace(/\n/g, ' ');

    if (fields.length > 1) {
      showAddressFields();
    }

    document.dispatchEvent(new CustomEvent(ADDRESS_FORM_EVENTS.POPULATE));
    document.dispatchEvent(new CustomEvent(ADDRESS_FORM_EVENTS.UPDATE));
    document.dispatchEvent(new CustomEvent(ADDRESS_FORM_EVENTS.REFRESH));
  });

  return control;
};

const initAddressSearch = () => {
  const lookupField = document.querySelector(SELECTORS.LOOKUP);
  const customLookupFields = document.querySelectorAll(SELECTORS.CUSTOM_LOOKUP);

  if (lookupField) {
    const fields = [
      { element: IDS.LOOKUP, field: '', mode: window.pca.fieldMode.SEARCH },
      { element: IDS.COUNTRY, field: 'Country', mode: window.pca.fieldMode.PRESERVE },
      { element: IDS.ADDRESS_FIELDS.LINE1, field: 'Line1', mode: window.pca.fieldMode.POPULATE },
      { element: IDS.ADDRESS_FIELDS.LINE2, field: 'Line2', mode: window.pca.fieldMode.POPULATE },
      { element: IDS.ADDRESS_FIELDS.LINE3, field: 'Line3', mode: window.pca.fieldMode.POPULATE },
      { element: IDS.ADDRESS_FIELDS.POSTAL_CODE, field: 'PostalCode', mode: window.pca.fieldMode.POPULATE },
      { element: IDS.ADDRESS_FIELDS.CITY, field: 'City', mode: window.pca.fieldMode.POPULATE },
      { element: IDS.ADDRESS_FIELDS.STATE, field: 'ProvinceName', mode: window.pca.fieldMode.POPULATE },
    ];

    const control = createControl(lookupField, fields);

    control.listen('load', () => {
      control.setCountry(document.querySelector(SELECTORS.COUNTRY).value);
    });


    control.load(); // Forces the 'load' event to be fired, it seems to be skipped on occasion
  }

  customLookupFields.forEach((customLookupField) => {
    const customFields = [
      { element: customLookupField, field: 'search', mode: window.pca.fieldMode.DEFAULT },
    ];

    createControl(customLookupField, customFields);
  });
};

export const initLookup = () => {
  // Set an interval to make sure window.pca is defined
  const pcaInterval = setInterval(() => {
    if (window.pca) {
      clearInterval(pcaInterval);
      initAddressSearch();

      onEvent(document, 'click', SELECTORS.TOGGLE, () => {
        showAddressFields();
      });

      [
        COLLAPSE_EVENTS.FORMS_STATE_CHANGED,
      ].forEach((evt) => {
        document.addEventListener(evt, () => {
          document.querySelectorAll(SELECTORS.ADDRESS_FIELDS).forEach((el) => {
            const toggle = document.querySelector(SELECTORS.TOGGLE);

            if (visible(toggle)) {
              el.disabled = false;
            }
          });
        });
      });

      [
        ADDRESS_FORM_EVENTS.REFRESH,
        CHECKOUT_EVENTS.SAVED_ADDRESS.ADDRESS_SELECTED,
        CHECKOUT_EVENTS.BILLING.USE_SHIPPING_AS_BILLING_TOGGLED,
        NAV_EVENTS.NAV_CONTENT,
        NAV_EVENTS.NAV_BACK,
      ].forEach((evt) => {
        document.addEventListener(evt, (e) => {
          initAddressSearch();

          if (e.detail && e.detail.error) {
            showAddressFields();
          }
        });
      });

      document.addEventListener(ADDRESS_FORM_EVENTS.SUBMIT, showAddressFieldsOnReinit);

      showAddressFieldsOnReinit();
    }
  }, 100);
};

export * from '~base';
