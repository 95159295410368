/* global grecaptcha */

const SELECTORS = {
  RECAPTCHA_ELEMENT: '.g-recaptcha-key',
};


export default() => {
  const placeholder = document.querySelector(SELECTORS.RECAPTCHA_ELEMENT);

  if (placeholder) {
    const form = placeholder.closest('form');
    if (form) {
      const btn = form.querySelector('[data-action=submit_captcha]');
      btn.disabled = true;
      const { sitekey } = placeholder.dataset;
      grecaptcha.ready(() => {
        grecaptcha.execute(sitekey, { action: 'submit_captcha' }).then((token) => {
          btn.disabled = false;
          form.querySelector('.g-recaptcha-response').value = token;
        });
      });
    }
  }
};
