'use strict';

// eslint-disable-next-line
// window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

import '~base';

$(document).ready(function () {
    processInclude(require('./components/search'));
    processInclude(require('./components/cloudinaryMiniCart'));
});
