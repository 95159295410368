/* eslint-disable jquery/no-val */
/* eslint-disable jquery/no-parent */
/* eslint-disable jquery/no-find */
/* eslint-disable jquery/no-closest */
/* eslint-disable jquery/no-class */
/* eslint-disable jquery/no-data */
import $ from 'jquery';
import Glide from '@glidejs/glide';

const SELECTORS = {
  MODAL: '#ATBRecommendationsModal',
  CONTAINER: '.ATBRecommendations-content',
  CAROUSEL_ANCHOR: '.addToBagOverlay-product-recommendations',
  CAROUSEL_ANCHOR_LOW_ASP: '.addToBagOverlay-product-recommendations-lowASP',
  NAVIGATORS: {
    ARROWS: '.glide__arrows',
  },
};

const CustomLength = function (glide, Components, Events) {
  const addToBagCarouselEvents = {
    handleSlider() {
      if (Components.Sizes.length <= glide.settings.perView) {
        glide.disable();
        Components.Html.root.querySelector(SELECTORS.NAVIGATORS.ARROWS).classList.add('d-none');
      } else {
        glide.enable();
        Components.Html.root.querySelector(SELECTORS.NAVIGATORS.ARROWS).classList.remove('d-none');
      }
    },
    mount() {
      this.handleSlider();
    },
  };
  Events.on('resize', () => {
    addToBagCarouselEvents.handleSlider();
  });
  return addToBagCarouselEvents;
};

const addToBagCarouselInit = function () {
  let slidesCount = 5;
  if ($('.ATBOverlayEnable').length > 0 && parseInt($('.ATBOverlayEnable').data('slides-perview'), 10) > 0) {
    slidesCount = parseInt($('.ATBOverlayEnable').data('slides-perview'), 10);
  }
  if ($(SELECTORS.CAROUSEL_ANCHOR).length > 0) {
    new Glide(SELECTORS.CAROUSEL_ANCHOR, {
      type: 'slider',
      perView: slidesCount,
      gap: 10,
      rewind: false,
      bound: true,
      peek: { before: 0, after: 0 },
      breakpoints: {
        1400: {
          perView: slidesCount,
          peek: { before: 0, after: 0 },
        },
        1200: {
          perView: 4,
          peek: { before: 0, after: 0 },
        },
        992: {
          perView: 3,
          peek: { before: 0, after: 0 },
        },
        768: {
          perView: 3,
          peek: { before: 0, after: 0 },
        },
        576: {
          perView: 1,
          peek: { before: 0, after: 150 },
        },
        375: {
          perView: 1,
          peek: { before: 0, after: 150 },
        },
      },
    }).mount({ CustomLength });
  }
};

const addToBagCarouselLowASPInit = function () {
  const slidesCount = 1;
  if ($(SELECTORS.CAROUSEL_ANCHOR_LOW_ASP).length > 0) {
    const lowASPRecommendations = document.querySelectorAll(SELECTORS.CAROUSEL_ANCHOR_LOW_ASP);
    for (let i = 0; i < lowASPRecommendations.length; i++) {
      new Glide(lowASPRecommendations[i], {
        type: 'slider',
        perView: slidesCount,
        gap: 16,
        rewind: false,
        bound: true,
        peek: { before: 0, after: 0 },
        breakpoints: {
          1400: {
            perView: slidesCount,
            peek: { before: 0, after: 0 },
          },
          1200: {
            perView: 1,
            peek: { before: 0, after: 0 },
          },
          992: {
            perView: 1,
            peek: { before: 0, after: 0 },
          },
          768: {
            perView: 1,
            peek: { before: 0, after: 0 },
          },
          576: {
            perView: 1,
            peek: { before: 0, after: 0 },
          },
          375: {
            perView: 1,
            peek: { before: 0, after: 0 },
          },
        },
      }).mount({ CustomLength });
    }
  }
};

export default() => {
  let lastClickedButton = null;
  let lastAddedProductID = null;
  let isATBRecommendationsModalShown = null;

  if ($(SELECTORS.MODAL).length > 0) {
    const showATBOverlayModal = (pid, isModalShown) => {
      // eslint-disable-next-line jquery/no-data
      const url = $(SELECTORS.MODAL).data('action');
      if (!url || !pid) {
        return;
      }
      $.spinner().start();
      // eslint-disable-next-line jquery/no-ajax
      $.ajax({
        url,
        type: 'get',
        data: {
          pid,
        },
        success: (data) => {
          $(SELECTORS.CONTAINER).empty();
          // eslint-disable-next-line jquery/no-html
          $(SELECTORS.CONTAINER).html(data);
          document.querySelector(SELECTORS.MODAL).classList.remove('modalWithoutCarousel');
          if (isModalShown) {
            // eslint-disable-next-line jquery/no-hide
            $('.ATBRecommendationsContainer').hide();
            document.querySelector(SELECTORS.MODAL).classList.add('modalWithoutCarousel');
          }

          if ($(SELECTORS.CAROUSEL_ANCHOR).length > 0) {
            addToBagCarouselInit();
          }
          if ($(SELECTORS.CAROUSEL_ANCHOR_LOW_ASP).length > 0) {
            addToBagCarouselLowASPInit();
          }
          $.spinner().stop();
        },
        error() {
          $(SELECTORS.MODAL).modal('hide');
          $.spinner().stop();
        },
      });

      $(SELECTORS.MODAL).modal('show');
    };

    $(document).on('updateAddToCartFormData', (e, data) => {
      if (!data.error) {
        lastClickedButton = e.target;
      }
    }).on('product:afterAddToCart', async (e, data) => {
      if (data && !data.error && lastClickedButton && lastClickedButton.dataset.pid) {
        lastAddedProductID = lastClickedButton.dataset.pid;
        isATBRecommendationsModalShown = $('#ATBRecommendationsModal').hasClass('in')
          || $('#ATBRecommendationsModal').hasClass('show');
        showATBOverlayModal(lastAddedProductID, isATBRecommendationsModalShown);
        lastClickedButton = null;
        lastAddedProductID = null;
        isATBRecommendationsModalShown = null;
      }
    });
  }

  document.addEventListener('addToBag:carousel-init', addToBagCarouselInit);
  document.addEventListener('addToBagLowASP:carousel-init', addToBagCarouselLowASPInit);

  $(document).on('click', '.product-tile__add-overlay-size', function () {
    if ($('.wishlist.container .wishlist__products').length > 0
    && $(this).closest('.ATBRecommendationsContainer')
    && $(this).parent().find('.add-to-cart')
    && !$(this).parent().find('.add-to-cart').data('url')
    && $(this).parent().find('.add-to-cart-url')
    && $(this).parent().find('.add-to-cart-url').val()) {
      $(this).parent().find('.add-to-cart').data('url', $(this).parent().find('.add-to-cart-url').val());
    }
  });
};
