import $ from 'jquery';
import debounce from '../util/debounce';

const CONSTANTS = {
  TRIGGER_OFFSET: 800,
  SCROLL_INTERVAL: 150,
};

const SELECTORS = {
  BACK_TO_TOP: '.back-to-top',
};

const EVENTS = {
  BACK_SHOW: 'backtotop:show',
  BACK_HIDE: 'backtotop:hide',
};

export const addBackToTopHandler = (bttSelector) => {
  const backToTopSelector = bttSelector || SELECTORS.BACK_TO_TOP;
  const backToTopLinks = document.querySelectorAll(backToTopSelector);

  if (backToTopLinks) {
    backToTopLinks.forEach((backToTopLink) => {
      const holder = (backToTopLink.dataset.containerSelector === undefined)
        ? window
        : document.querySelector(backToTopLink.dataset.containerSelector);

      const scrollHandler = debounce(() => {
        const triggerOffset = backToTopLink.dataset.triggerOffset || CONSTANTS.TRIGGER_OFFSET;
        const y = holder.pageYOffset || holder.scrollTop;

        if (y >= triggerOffset) {
          backToTopLink.classList.add('active');
        } else {
          backToTopLink.classList.remove('active');
        }
      }, CONSTANTS.SCROLL_INTERVAL);

      holder.addEventListener('scroll', scrollHandler);

      backToTopLink.addEventListener('click', () => {
        const container = (backToTopLink.dataset.containerSelector === undefined)
          ? $('html, body')
          : $(backToTopLink.dataset.containerSelector);
        container.animate({
          scrollTop: 0,
        }, 500);
      });
      document.addEventListener(EVENTS.BACK_SHOW, () => {
        backToTopLink.classList.remove('d-none');
      });
      document.addEventListener(EVENTS.BACK_HIDE, () => {
        backToTopLink.classList.add('d-none');
      });
    });
  }
};

/**
 * show and use back to top.
 */
export default () => {
  addBackToTopHandler();
};
