import base from '~base';

import $ from 'jquery';
import debounce from '../util/debounce';
import { onEvent, visible } from '../util/domutils'; // eslint-disable-line import/named
import { functions as headerFunctions } from './header';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { loadContent } from '../util/fetchutils'; // eslint-disable-line import/named

const CLASSES = {
  SEARCH_FIELD: 'search-field',
  CLOSE_SEARCH: 'close-search',
  TRENDING_PANE: 'trending-suggestions',
  get TRENDING_PANE_ACTIVE() { return `${this.TRENDING_PANE}--active`; },
};

const SELECTORS = {
  WRAPPER: '.suggestions-wrapper',
  SEARCH_FIELD: `.${CLASSES.SEARCH_FIELD}`,
  CLEAR_SEARCH: '.clear-search',
  TRENDING_PANE: `.${CLASSES.TRENDING_PANE}`,
  SEARCHESUGGESTIONS_PANE: '.suggestions',
  SUGGESTIONS_CLOSE: '.suggestions .close-search',
  LOAD_RECENT_SEARCH: '.trending-suggestions .js-load-recent-search',
  LOAD_POP_SEARCH: '.js-load-pop-search',
};

const handleSuggestionsDisplay = () => {
  const headerHeight = headerFunctions.getHeaderHeight();
  const panes = document.querySelectorAll(SELECTORS.SEARCHESUGGESTIONS_PANE);

  panes.forEach((pane) => {
    const isVisible = visible(pane);
    const maxHeight = window.innerHeight - headerHeight;
    const isTallerThanWindow = pane.scrollHeight >= maxHeight;

    if (isVisible) {
      pane.style.maxHeight = `${maxHeight}px`;
    }

    if (isVisible && isTallerThanWindow) {
      disableBodyScroll(pane);
    } else {
      enableBodyScroll(pane);
    }
  });
};

const loadRecentSearches = async () => {
  const recentSearch = document.querySelector(SELECTORS.LOAD_RECENT_SEARCH);
  if (recentSearch && recentSearch.children.length <= 0) {
    const { content } = await loadContent(recentSearch, null, null, false);

    recentSearch.innerHTML = content;
  }
};

const toggleTrendingSuggestions = (field, trendingPane) => {
  loadRecentSearches();
  if (!field.value || field.value.length < 3) {
    trendingPane.classList.add(CLASSES.TRENDING_PANE_ACTIVE);
  } else {
    trendingPane.classList.remove(CLASSES.TRENDING_PANE_ACTIVE);
  }

  handleSuggestionsDisplay();
};

const clearSearchField = () => {
  const clearSearchToggles = document.querySelectorAll(SELECTORS.CLEAR_SEARCH);

  clearSearchToggles.forEach((clearSearch) => {
    clearSearch.addEventListener('click', () => {
      const searchField = clearSearch.parentElement.parentElement.querySelector(SELECTORS.SEARCH_FIELD);

      if (searchField) {
        searchField.value = '';
        searchField.focus();
        searchField.click();
      }
    });
  });
};

const trendingSuggestions = () => {
  const trendingPane = document.querySelector(SELECTORS.TRENDING_PANE);

  if (trendingPane) {
    const searchField = trendingPane.parentElement.querySelector(SELECTORS.SEARCH_FIELD);

    if (searchField) {
      const debounceTrending = debounce(toggleTrendingSuggestions, 300);

      ['keyup', 'click'].forEach((evt) => {
        searchField.addEventListener(evt, () => {
          debounceTrending(searchField, trendingPane);
        });
      });

      document.body.addEventListener('click', (e) => {
        if (
          e.target !== trendingPane
          && !trendingPane.contains(e.target)
          && !e.target.classList.contains(CLASSES.SEARCH_FIELD)
        ) {
          trendingPane.classList.remove(CLASSES.TRENDING_PANE_ACTIVE);
        }

        setTimeout(handleSuggestionsDisplay);
      });
    }
  }
};

const closeSuggestions = () => {
  onEvent(document, 'click', SELECTORS.SUGGESTIONS_CLOSE, () => {
    const searchField = document.querySelector(SELECTORS.SEARCH_FIELD);

    if (searchField) {
      searchField.blur();
      searchField.parentElement.click();
    }
  });
};

const handleSuggestionsVisibility = () => {
  const wrapper = document.querySelector(SELECTORS.WRAPPER);
  const url = wrapper && wrapper.dataset.url;

  $(document).ajaxComplete((e, xhr, options) => {
    if (url && options.url && options.url.indexOf(url) >= 0) {
      handleSuggestionsDisplay();
    }
  });

  window.addEventListener('resize', debounce(handleSuggestionsDisplay));
};

const loadPopularSearches = async () => {
  const loadPopSearch = document.querySelector(SELECTORS.LOAD_POP_SEARCH);

  if (loadPopSearch) {
    const { content } = await loadContent(loadPopSearch, null, null, false);

    loadPopSearch.innerHTML = content;
  }
};

export default () => {
  base();
  loadPopularSearches();
  clearSearchField();
  trendingSuggestions();
  closeSuggestions();
  handleSuggestionsVisibility();
};
