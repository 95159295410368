/* eslint-disable jquery/no-class */
/* eslint-disable max-len */
import $ from 'jquery';
import convertSizes from '../components/sizeConversions';
import { getSiblings, onEvent } from '../util/domutils'; // eslint-disable-line import/named
import { appendToUrl } from '../util/urlutils'; // eslint-disable-line import/named
import { loadContent, CONTENT_TYPES } from '../util/fetchutils'; // eslint-disable-line import/named

const CLASSES = {
  SWATCH: 'product-tile__swatch',
  TILE_IMAGE_CONTAINER: 'product-tile__image',
  TILE_IMAGE_CONTAINER_OVERLAY_SHOWN: 'product-tile__image--add-overlay',
  SWATCH_CURRENT: 'product-tile__swatch--current',
  OUT_OF_STOCK: 'outofstock',
};

const SELECTORS = {
  TILE: '.product-tile',
  SWATCH: `.${CLASSES.SWATCH}`,
  TILE_IMAGE_CONTAINER: `.${CLASSES.TILE_IMAGE_CONTAINER}`,
  TILE_IMAGE: `.${CLASSES.TILE_IMAGE_CONTAINER} .tile-image`,
  PRIMARY_IMAGE: `.${CLASSES.TILE_IMAGE_CONTAINER} .primary-image`,
  ALTERNATE_IMAGE: `.${CLASSES.TILE_IMAGE_CONTAINER} .alternate-image`,
  ALTERNATE_TILE_IMAGE: `.${CLASSES.TILE_IMAGE_CONTAINER} img.alternate-image`,
  PDP_LINK: '.pdp-link .primary-image.link',
  ALTERNATE_PDP_LINK: '.pdp-link .alternate-image.link',
  ADD_TO_BAG_LINK: '.product-tile__action-add',
  ADD_TO_BAG_OVERLAY: '.product-tile__add-overlay',
  ADD_TO_BAG_OVERLAY_CLOSE: '.product-tile__add-overlay-close',
  PRICE_CONTAINER: '.product-tile__price .link',
  PROMO_CONTAINER: '.product-tile__promotions .link',
  SUGGESTIONS_LINK: '.suggestions-categories .product .link',
};

export const EVENTS = {
  SWATCH_CHANGE: 'tile:swatch-changed',
  ADD_TO_BAG_OVERLAY_TOGGLE: 'tile:overlay-toggle',
};

/**
 * Retrieves all sibling color swatches of the parameter
 *
 * @param  {Element}   swatch The swatch whose siblings to retrieve
 * @return {Element[]} The sibling swatches
 */
const getSwatchSiblings = (swatch) => {
  const otherSwatches = getSiblings(swatch, (el) => {
    const isNode = !!el.classList;
    const isSwatch = isNode && el.classList.contains(CLASSES.SWATCH);
    const isOtherSwatch = isSwatch && el !== swatch;

    return isOtherSwatch;
  });

  return otherSwatches;
};

/**
 * Handles the necessary changes to a product tile whose color swatch has been clicked
 *
 * @param  {Element} tile The tile to update
 * @param  {Element} clickedSwatch The color swatch whose click needs to be handled
 */
const updateTileElements = (tile, clickedSwatch) => {
  const image = tile.querySelector(SELECTORS.TILE_IMAGE);
  const links = tile.querySelectorAll(SELECTORS.PDP_LINK);
  const addToBag = tile.querySelector(SELECTORS.ADD_TO_BAG_LINK);
  const priceContainer = tile.querySelector(SELECTORS.PRICE_CONTAINER);
  const promoContainer = tile.querySelector(SELECTORS.PROMO_CONTAINER);

  image.src = clickedSwatch.dataset.image;
  image.dataset.src = clickedSwatch.dataset.image;

  links.forEach((link) => {
    const hash = link.href.split('#')[1];

    link.href = clickedSwatch.href;

    if (hash) {
      link.href += `#${hash}`;
    }
  });

  if (addToBag) {
    addToBag.dataset.action = clickedSwatch.dataset.addToBagUrl;
  }

  if (priceContainer) {
    priceContainer.innerHTML = clickedSwatch.dataset.priceHtml;
  }

  if (promoContainer) {
    promoContainer.innerHTML = clickedSwatch.dataset.promoCalloutsHtml;
  }
};

const updateTileElementsforAlternateImage = (tile, clickedSwatch) => {
  const alternateImage = tile.querySelector(SELECTORS.ALTERNATE_TILE_IMAGE);
  const links = tile.querySelectorAll(SELECTORS.ALTERNATE_PDP_LINK);
  const primaryImageCarouselItem = tile.querySelector(SELECTORS.PRIMARY_IMAGE);
  const AlternateImageCarouselItem = tile.querySelector(SELECTORS.ALTERNATE_IMAGE);

  if (alternateImage) {
    alternateImage.src = clickedSwatch.dataset.alternateImageUrl;
    alternateImage.dataset.src = clickedSwatch.dataset.alternateImageUrl;
    alternateImage.srcset = clickedSwatch.dataset.alternateImageSrcset;
    primaryImageCarouselItem.classList.add('active');
    AlternateImageCarouselItem.classList.remove('active');
  }

  if (links) {
    links.forEach((link) => {
      const hash = link.href.split('#')[1];

      link.href = clickedSwatch.href;

      if (hash) {
        link.href += `#${hash}`;
      }
    });
  }
};

/**
 * Ensures that the provided swatch gets marked as selected,
 * and all other swatches get marked as unselected
 *
 * @param  {Element} clickedSwatch The swatch to select
 */
const handleSwatchSelection = (clickedSwatch) => {
  const otherSwatches = getSwatchSiblings(clickedSwatch);

  otherSwatches.forEach((otherSwatch) => {
    otherSwatch.classList.remove(CLASSES.SWATCH_CURRENT);
  });

  clickedSwatch.classList.add(CLASSES.SWATCH_CURRENT);
};

const dispatchColorChangeEvent = (tile, selectedSwatch) => {
  const evt = new CustomEvent(EVENTS.SWATCH_CHANGE, {
    detail: {
      tile,
      selectedSwatch,
    },
  });

  document.dispatchEvent(evt);
};

const dispatchAddToBagOverlayEvent = (tile, shown) => {
  const evt = new CustomEvent(EVENTS.ADD_TO_BAG_OVERLAY_TOGGLE, {
    detail: {
      tile,
      shown,
    },
  });

  document.dispatchEvent(evt);
};

const displayAddToBagOverlay = async (addToBagLink) => {
  const { type: contentType, content } = await loadContent(addToBagLink);

  if (contentType === CONTENT_TYPES.TEXT) {
    const imageContainer = addToBagLink.closest(SELECTORS.TILE_IMAGE_CONTAINER);
    const existingOverlay = imageContainer.querySelector(SELECTORS.ADD_TO_BAG_OVERLAY);

    if (!existingOverlay) {
      imageContainer.innerHTML += content;
      imageContainer.classList.add(CLASSES.TILE_IMAGE_CONTAINER_OVERLAY_SHOWN);

      convertSizes();

      dispatchAddToBagOverlayEvent(imageContainer.closest(SELECTORS.TILE), true);
    }
  } else if (contentType === CONTENT_TYPES.JSON) {
    if (!content.error) {
      // eslint-disable-next-line jquery/no-trigger
      $('.minicart').trigger('count:update', content);
      if ($('.cart-page').length > 0 || $('#ATBRecommendationsModal').length > 0) {
        // eslint-disable-next-line jquery/no-trigger
        $.when($(addToBagLink).trigger('updateAddToCartFormData', content)).done(() => {
          // eslint-disable-next-line jquery/no-trigger
          $('body').trigger('product:afterAddToCart', content);
        });
      }
    }
  }
};

const closeAddToBagOverlay = async (overlay) => {
  if (overlay) {
    const tile = overlay.closest(SELECTORS.TILE);
    const imageVeiled = overlay.closest(SELECTORS.TILE_IMAGE_CONTAINER);

    overlay.remove();

    imageVeiled.classList.remove('veiled');
    imageVeiled.style.position = null;
    imageVeiled.classList.remove(CLASSES.TILE_IMAGE_CONTAINER_OVERLAY_SHOWN);

    dispatchAddToBagOverlayEvent(tile, false);
  }
};

const handleAddToBagActions = () => {
  let lastClickedButton = null;

  $(document).on('updateAddToCartFormData', (e, data) => {
    const button = e.target;

    data.pid = button.dataset.pid;
    data.quantity = button.dataset.minQuantity || data.quantity;

    lastClickedButton = button;
  }).on('product:afterAddToCart', () => {
    if (lastClickedButton) {
      const overlay = lastClickedButton.closest(SELECTORS.ADD_TO_BAG_OVERLAY);

      closeAddToBagOverlay(overlay);

      lastClickedButton = null;
    }
  });

  document.addEventListener(EVENTS.SWATCH_CHANGE, (e) => {
    const overlay = e.detail.tile.querySelector(SELECTORS.ADD_TO_BAG_OVERLAY);
    closeAddToBagOverlay(overlay);
  });
};

const handleStickers = (tile, swatch) => {
  const { selectable } = swatch.dataset;
  tile.classList.remove(CLASSES.OUT_OF_STOCK);
  if (selectable !== 'true') {
    tile.classList.add(CLASSES.OUT_OF_STOCK);
  }
};

export const colorSwatches = () => {
  $(document).on('click', SELECTORS.SWATCH, (e) => {
    e.preventDefault();

    const swatch = e.currentTarget;
    const tile = swatch.closest(SELECTORS.TILE);
    const showAltImgonPLP = tile.getAttribute('data-showaltimg');

    updateTileElements(tile, swatch);
    if (showAltImgonPLP !== null) {
      updateTileElementsforAlternateImage(tile, swatch);
    }
    handleSwatchSelection(swatch);
    handleStickers(tile, swatch);
    dispatchColorChangeEvent(tile, swatch);
  });
};

export const quickAddToBag = () => {
  onEvent(document, 'click', SELECTORS.ADD_TO_BAG_LINK, async (e) => {
    e.preventDefault();

    if (!e.delegateTarget.classList.contains('disabled')) {
      displayAddToBagOverlay(e.delegateTarget);
    }
  });

  onEvent(document, 'click', SELECTORS.ADD_TO_BAG_OVERLAY_CLOSE, async (e) => {
    e.preventDefault();

    const overlay = e.delegateTarget.closest(SELECTORS.ADD_TO_BAG_OVERLAY);

    closeAddToBagOverlay(overlay);
  });

  handleAddToBagActions();
};

export const addSearchSuggestionPhraseParam = () => {
  onEvent(document, 'click', SELECTORS.SUGGESTIONS_LINK, (e) => {
    let searchPhrase;
    const linkToPdp = e.delegateTarget;
    const searchInputs = document.querySelectorAll('.search-field');

    searchInputs.forEach((searchField) => {
      if (searchField.value !== '') {
        searchPhrase = searchField.value;
      }
    });

    if (searchPhrase) {
      e.preventDefault();
      window.location.href = appendToUrl(linkToPdp.href, { q: searchPhrase });
    }
  });
};

export const preOrderVarient = () => {
  onEvent(document, 'click', '.product-tile__add-overlay-size', (e) => {
    const preOrderData = JSON.parse(e.srcElement.getAttribute('data-preOrderData'));
    const preOrderMessageContainer = e.srcElement.closest('.product-tile__add-overlay-sizes-container').querySelector('.product-tile__add-overlay-pre-order');
    const preOrderMessage = e.srcElement.closest('.product-tile__add-overlay-sizes-container').querySelector('.product-tile__add-overlay-pre-order-availability-message');
    if (preOrderData) {
      preOrderMessage.innerText = preOrderData.preorderAvailabilityMessage;
      if (preOrderMessageContainer.classList.contains('d-none')) {
        preOrderMessageContainer.classList.remove('d-none');
      }
    } else if (!preOrderMessageContainer.classList.contains('d-none')) {
      preOrderMessageContainer.classList.add('d-none');
    }
  });
};

export const enableSlideonPLPAltImg = () => {
  if ($('.carousel').length > 0) {
    $('.carousel').on('touchstart', function (event) {
      const xClick = event.originalEvent.touches[0].pageX;
      $(this).one('touchmove', function (e) {
        const xMove = e.originalEvent.touches[0].pageX;
        const sensitivityInPx = 5;

        if (Math.floor(xClick - xMove) > sensitivityInPx) {
          $(this).carousel('next');
        } else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
          $(this).carousel('prev');
        }
      });
      $(this).on('touchend', function () {
        $(this).off('touchmove');
      });
    });
  }
};

document.addEventListener('tile:overlay-toggle', () => {
  enableSlideonPLPAltImg();
});

$(document).on('click', '.bestprice .price-label, .bestprice-close-btn', (e) => {
  e.preventDefault();
  if ($('#bestprice').hasClass('d-none')) {
    $('.price-label').addClass('active');
    $('#bestprice').removeClass('d-none');
    // eslint-disable-next-line jquery/no-css
    $('.price-label').css('textDecoration', 'none');
  } else {
    $('#bestprice').addClass('d-none');
    $('.price-label').removeClass('active');
    // eslint-disable-next-line jquery/no-css
    $('.price-label').css('textDecoration', 'underline');
  }
  if ($('.bestprice-close-btn').hasClass('d-none')) {
    $('.bestprice-close-btn').removeClass('d-none');
  } else {
    $('.bestprice-close-btn').addClass('d-none');
  }
});
