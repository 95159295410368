/**
 * Initializes the application.
 *
 * Sets the publicPath for Webpack and provides the loadChunks function to load the needed client side scripts.
 */
import loadChunksVal from './util/loadChunks.val';
import appConfig from './internal/appConfig';

const { basePath } = appConfig;

// set public path if needed. If webpack-dev-server is running this has a value assigned.
if (!__webpack_public_path__ && basePath) { // eslint-disable-line camelcase
  __webpack_public_path__ = basePath; // eslint-disable-line camelcase
}

/**
 * Loads the needed scripts provided via the additionalScripts config.
 */
export function loadChunks() { // eslint-disable-line import/prefer-default-export
  const { additionalScripts } = appConfig;
  if (additionalScripts) {
    loadChunksVal(additionalScripts);
  }
}
