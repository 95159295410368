import base from '~base';

import $ from 'jquery';
import scrollTo from '../util/scrollTo';
import { find, visible } from '../util/domutils';

const SELECTORS = {
  COLLAPSE: '.collapse',
  TABS: '[data-toggle="tab"]',
};

const baseLoadFormErrors = base.loadFormErrors;

export const loadFormErrors = (parentSelector, fieldErrors) => {
  baseLoadFormErrors.call(null, parentSelector, fieldErrors);

  const errorFields = find('.is-invalid:not([type="hidden"])', parentSelector);

  if (errorFields && errorFields.length > 0) {
    if (!visible(errorFields[0])) {
      const collapse = errorFields[0].closest(SELECTORS.COLLAPSE);

      if (collapse) {
        $(collapse).on('shown.bs.collapse', () => {
          scrollTo(errorFields[0]);
          errorFields[0].focus();
        });

        $(collapse).collapse('show');
      }
    } else {
      scrollTo(errorFields[0]);
      errorFields[0].focus();
    }
  }
};

export const { clearPreviousErrors } = base;
