import $ from 'jquery';
import { onEvent, isLG } from '../util/domutils'; // eslint-disable-line import/named
import { EVENTS as FLYOUT_EVENTS } from './flyoutUnderHeader';

const CLASSES = {
  MENU: 'nav-menu',
  MENU_LEFT: 'nav-menu--left',
  MENU_RIGHT: 'nav-menu--right',
  MENU_VISIBLE: 'nav-menu--visible',
  MENU_TOGGLED: 'nav-menu--toggled',
  BACKGROUND_VISIBLE: 'show',
  MENU_TOGGLER_ACTIVE: 'expanded',
  BODY_MENU_TOGGLED_LEFT: 'nav-menu-toggled--left',
  BODY_MENU_TOGGLED_RIGHT: 'nav-menu-toggled--right',
  MENU_SLIDE: 'nav-menu--slide',
  TOUCH_FOCUS: 'touch-focus',
  CATEGORY_COLLAPSE: 'collapse',
};

const ATTRIBUTES = {
  TOGGLE: 'data-toggle',
  DISABLED_TOGGLE: 'data-mobile-toggle',
};

const SELECTORS = {
  MENU: `.${CLASSES.MENU}`,
  MENU_BODY: '.nav-menu__body',
  MENU_LINK: '.nav-menu__item-link',
  BACK_LINK: '.nav-menu__back-link',
  BACK_LINK_CONTAINER: '.nav-menu__back',
  SECOND_LEVEL_CATEGORIES: '.nav-menu__body .nav-menu__items--level-2',
  THIRD_LEVEL_CATEGORIES: '.nav-menu__body .nav-menu__items--level-3',
  MENU_TOGGLES: `[data-toggle='.${CLASSES.MENU}']`,
  BACKGROUND: '.modal-background',
  TOP_LEVEL_LINK: '.nav-menu__body .nav-menu__items--level-1 > .nav-menu__item > .nav-menu__item-link',
  TOGGLE: `[${ATTRIBUTES.TOGGLE}]`,
  DISABLED_TOGGLE: `[${ATTRIBUTES.DISABLED_TOGGLE}]`,
  get TOP_LEVEL_NAV_MENU_LINKS() { return `${this.MENU_LINK}--level-1`; },
  get SECOND_LEVEL_NAV_MENU_LINKS() { return `${this.MENU_LINK}--level-2`; },
  get EXPANDED_NAV_MENU_LINKS() { return `${this.MENU_LINK}[aria-expanded="true"]`; },
  get COLLAPSE_NAV_MENU_LINKS() {
    return `${this.TOP_LEVEL_NAV_MENU_LINKS}, ${this.SECOND_LEVEL_NAV_MENU_LINKS}`;
  },
};

const slideMenu = menu => menu.classList.contains(CLASSES.MENU_SLIDE);

const hasSubMenus = el => el.parentElement.querySelectorAll(SELECTORS.SECOND_LEVEL_CATEGORIES).length > 0;

const hideMenuOnTypeChange = (menu, menuToggles) => {
  window.addEventListener('resize', () => {
    if (isLG()) {
      const activeCollapseLinks = menu.querySelectorAll(SELECTORS.EXPANDED_NAV_MENU_LINKS);

      // Prevents expanded subcategory levels from leaving the desktop-style menu invisible
      activeCollapseLinks.forEach((link) => {
        let action;
        if (link.matches(SELECTORS.TOP_LEVEL_NAV_MENU_LINKS)) {
          action = 'hide';
        } else if (link.matches(SELECTORS.SECOND_LEVEL_NAV_MENU_LINKS)) {
          action = 'show';
        }

        if (action) {
          $(link.dataset.target).collapse(action);
        }
      });

      // Closest the mobile-style menu
      if (menu.classList.contains(CLASSES.MENU_VISIBLE)) {
        menuToggles[0].click();
      }
    }
  });
};

const toggleCollapsesBehavior = (menu) => {
  ['load', 'resize'].forEach((event) => {
    window.addEventListener(event, () => {
      const collapseSelector = slideMenu(menu) ? SELECTORS.COLLAPSE_NAV_MENU_LINKS : SELECTORS.TOP_LEVEL_NAV_MENU_LINKS;
      const collapseTriggers = document.querySelectorAll(collapseSelector);
      const menuSelector = document.querySelector(SELECTORS.MENU);
      const menuCategories = document.querySelectorAll(SELECTORS.THIRD_LEVEL_CATEGORIES);

      menuCategories.forEach((categoryList) => {
        if (isLG() || !slideMenu(menu)) {
          categoryList.classList.remove(CLASSES.CATEGORY_COLLAPSE);
        } else {
          categoryList.classList.add(CLASSES.CATEGORY_COLLAPSE);
        }
      });

      if (isLG()) {
        menuSelector.classList.remove(CLASSES.MENU_TOGGLED);
      }

      collapseTriggers.forEach((trigger) => {
        if (isLG() || !hasSubMenus(trigger)) {
          trigger.setAttribute(ATTRIBUTES.DISABLED_TOGGLE, 'collapse');
          trigger.removeAttribute(ATTRIBUTES.TOGGLE);
        } else {
          trigger.setAttribute(ATTRIBUTES.TOGGLE, 'collapse');
          trigger.removeAttribute(ATTRIBUTES.DISABLED_TOGGLE);
        }
      });
    });
  });
};

const initSlideBehavior = (menu) => {
  if (slideMenu(menu)) {
    document.querySelectorAll(SELECTORS.TOP_LEVEL_LINK).forEach((link) => {
      link.addEventListener('click', () => {
        if (!isLG() && hasSubMenus(link)) {
          menu.classList.add(CLASSES.MENU_TOGGLED);
        }
      });
    });
    document.querySelectorAll(SELECTORS.BACK_LINK).forEach((link) => {
      link.addEventListener('click', () => {
        menu.classList.remove(CLASSES.MENU_TOGGLED);
      });
    });
  }
};

const getMenuToggledBodyClass = (menu) => {
  switch (true) {
    case menu.classList.contains(CLASSES.MENU_LEFT):
      return CLASSES.BODY_MENU_TOGGLED_LEFT;
    case menu.classList.contains(CLASSES.MENU_RIGHT):
      return CLASSES.BODY_MENU_TOGGLED_RIGHT;
    default:
      return null;
  }
};

const restoreMobileMenuInitialState = (menu) => {
  setTimeout(() => {
    if (slideMenu(menu)) {
      menu.classList.remove(CLASSES.MENU_TOGGLED);

      $(SELECTORS.BACK_LINK_CONTAINER).collapse('hide');
      $(SELECTORS.SECOND_LEVEL_CATEGORIES).collapse('hide');
      $(SELECTORS.THIRD_LEVEL_CATEGORIES).collapse('show');
    } else {
      $(SELECTORS.COLLAPSE_NAV_MENU_LINKS).collapse('hide');
    }
  }, 500); // Ensures the reset is executed after the menu has closed, so it's not visible to the customer
};

const toggleMenuElements = (menu, background, menuToggles, state) => {
  const menuBody = document.querySelector(SELECTORS.MENU_BODY);

  if (typeof state !== 'undefined') {
    menu.classList.toggle(CLASSES.MENU_VISIBLE, state);
    background.classList.toggle(CLASSES.BACKGROUND_VISIBLE, state);
    menuToggles.forEach((el) => {
      el.classList.toggle(CLASSES.MENU_TOGGLER_ACTIVE, state);
    });

    if (isLG()) {
      document.body.classList.remove(getMenuToggledBodyClass(menu));
    } else {
      document.body.classList.toggle(getMenuToggledBodyClass(menu), state);
    }
  } else {
    menu.classList.toggle(CLASSES.MENU_VISIBLE);
    background.classList.toggle(CLASSES.BACKGROUND_VISIBLE);
    menuToggles.forEach((el) => {
      el.classList.toggle(CLASSES.MENU_TOGGLER_ACTIVE);
    });

    if (isLG()) {
      document.body.classList.remove(getMenuToggledBodyClass(menu));
    } else {
      document.body.classList.toggle(getMenuToggledBodyClass(menu));
    }
  }

  if (menu.classList.contains(CLASSES.MENU_VISIBLE)) {
    document.dispatchEvent(new CustomEvent(FLYOUT_EVENTS.FLYOUT_OPEN, {
      detail: {
        content: menuBody,
      },
    }));
  } else {
    document.dispatchEvent(new CustomEvent(FLYOUT_EVENTS.FLYOUT_CLOSE, {
      detail: {
        content: menuBody,
      },
    }));

    restoreMobileMenuInitialState(menu);
  }
};

const initMobileMenu = (menu, menuToggles, background) => {
  initSlideBehavior(menu);

  onEvent(document, 'click', SELECTORS.MENU_TOGGLES, () => {
    toggleMenuElements(menu, background, menuToggles);
  });

  document.addEventListener(FLYOUT_EVENTS.FLYOUT_OPEN, (e) => {
    const menuBody = document.querySelector(SELECTORS.MENU_BODY);

    if (menu.classList.contains(CLASSES.MENU_VISIBLE) && e.detail.content !== menuBody) {
      toggleMenuElements(menu, background, menuToggles, false);
    }
  });
};

const initTouchNavigation = () => {
  const links = Array.from(document.querySelectorAll(SELECTORS.TOP_LEVEL_LINK));

  links.forEach((link) => {
    if (!hasSubMenus(link)) {
      return;
    }

    link.addEventListener('touchstart', (e) => {
      if (!link.getAttribute(ATTRIBUTES.TOGGLE) && !link.classList.contains(CLASSES.TOUCH_FOCUS)) {
        e.preventDefault();

        links.forEach((currentLink) => {
          currentLink.classList.toggle(CLASSES.TOUCH_FOCUS, currentLink === link);
        });
      }
    });

    link.addEventListener('click', (e) => {
      setTimeout(() => { // Move to the next tick to give time to the touchstart handler to add the class
        if (link.classList.contains(CLASSES.TOUCH_FOCUS)) {
          e.preventDefault();

          link.classList.remove(CLASSES.TOUCH_FOCUS);
        }
      });
    });
  });
};

export default () => {
  const menu = document.querySelector(SELECTORS.MENU);
  const background = document.querySelector(SELECTORS.BACKGROUND);
  const menuToggles = document.querySelectorAll(SELECTORS.MENU_TOGGLES);

  if (menu && background && menuToggles) {
    initMobileMenu(menu, menuToggles, background);
    hideMenuOnTypeChange(menu, menuToggles);
    toggleCollapsesBehavior(menu);
    initTouchNavigation();
  }
};
