import $ from 'jquery';
import Choices from 'choices.js';
import { initFlyout } from '../flyoutUnderHeader';
import { loadContent } from '../../util/fetchutils';
import { getJSONData } from '../../util/domutils'; // eslint-disable-line import/named
import { SELECTORS as HEADER_SELECTORS } from '../header';
import countryItem from './templates/countrysSelectorItem.mustache';
import countryChoice from './templates/countrySelectorChoice.mustache';
import countryContent from './templates/partials/countryContent.mustache';
import countryAttributes from './templates/partials/countryAttributes.mustache';

const CONSTANTS = {
  DROPDOWN_LIST_MAX_HEIGHT_OFFSET: 20,
};

const GLOBALS = {
  GATEWAY: {
    COUNTRY_CHOICES: null,
    LANGUAGE_CHOICES: null,
    CURRENCY_CHOICES: null,
  },
  CONTAINER: {
    COUNTRY_CHOICES: null,
    LANGUAGE_CHOICES: null,
    CURRENCY_CHOICES: null,
  },
};

const clearExistingDropdown = async () => {
  ['GATEWAY', 'CONTAINER'].forEach((containerType) => {
    if (GLOBALS[containerType].COUNTRY_CHOICES && GLOBALS[containerType].COUNTRY_CHOICES.initialised) {
      GLOBALS[containerType].COUNTRY_CHOICES.destroy();
    }
    if (GLOBALS[containerType].LANGUAGE_CHOICES && GLOBALS[containerType].LANGUAGE_CHOICES.initialised) {
      GLOBALS[containerType].LANGUAGE_CHOICES.destroy();
    }
    if (GLOBALS[containerType].CURRENCY_CHOICES && GLOBALS[containerType].CURRENCY_CHOICES.initialised) {
      GLOBALS[containerType].CURRENCY_CHOICES.destroy();
    }
  });
};

const CLASSES = {
  DROPDOWN_LIST: 'choices__list--dropdown',
};

const EVENTS = {
  UPDATE_HEADER: 'header:update',
};

const SELECTORS = {
  GATEWAY: '#countryGateway',
  CONTAINER: '.country-selector__pane',
  FORM: '.country-selector__form',
  FORM_ERROR: '.error-summary',
  DROPDOWN_CONTAINER: '.country-selector__form__field',
  LOCATION_DROPDOWN: '.country-selector__form__input--location',
  LANGUAGE_DROPDOWN: '.country-selector__form__input--language',
  CURRENCY_DROPDOWN: '.country-selector__form__input--currency',
  LANGUAGE_INPUT: '[type="hidden"][name="language"]',
  CURRENCY_INPUT: '[type="hidden"][name="currency"]',
  PAGE_LOCALE_LOADER: '.js-page-locale-loader',
  PAGE_LOCALE_LOADER_MOBILE: '.js-page-locale-loader-mobile',
};

/**
 * Renders the Mustache template for the card item or option
 * @param  {Function} templateFunc The item template function provided by the Choice library
 * @param  {Function} template     The Hogan renderer to use to render the content
 * @param  {Object}   classNames   The Choice class names hash, provided by the library
 * @param  {Object}   data         The item state hash, provided by the library
 * @return {string}                The result of calling the templating function with the rendered Hogan template
 */
const renderCountryOption = (templateFunc, template, classNames, data) => {
  const content = template.render(
    { classNames, data, countryCode: data.value.toLowerCase() },
    { content: countryContent, attributes: countryAttributes },
  );

  return templateFunc(content);
};

const handleDropdownBehavior = (choices, options, singleOptionBehaviors, singleOptionBehavior) => {
  const container = choices.containerOuter.element.closest(SELECTORS.DROPDOWN_CONTAINER);

  if (options.length <= 1) {
    switch (singleOptionBehavior) {
      case singleOptionBehaviors.HIDE:
        container.classList.add('d-none');
        break;
      case singleOptionBehaviors.DISABLE:
        choices.disable();
        break;
      default:
    }
  } else {
    choices.enable();
    container.classList.remove('d-none');
  }
};

const initCountryChange = (
  countryDropdown,
  languageChoices,
  currencyChoices,
  singleOptionBehaviors,
  singleOptionBehavior,
) => {
  const { form } = countryDropdown;
  const countries = getJSONData(countryDropdown, 'countries', []);

  countryDropdown.addEventListener('change', (e) => {
    const selectedCountryCode = e.detail.value;
    const selectedCountry = countries.find(country => country.id === selectedCountryCode);

    if (selectedCountry) {
      const languageInput = form.querySelector(SELECTORS.LANGUAGE_INPUT);
      const currencyInput = form.querySelector(SELECTORS.CURRENCY_INPUT);

      if (selectedCountry.locales.length) {
        languageInput.value = selectedCountry.locales[0].id;
      }
      if (selectedCountry.currencies.length) {
        currencyInput.value = selectedCountry.currencies[0].code;
      }

      if (languageChoices) {
        const languages = selectedCountry.locales.map((locale, index) => ({
          value: locale.id,
          label: locale.languageName,
          selected: index === 0,
        }));

        languageChoices.setChoices(languages, 'value', 'label', true);

        handleDropdownBehavior(languageChoices, languages, singleOptionBehaviors, singleOptionBehavior);

        languageChoices.passedElement.element.addEventListener('change', (evt) => {
          languageInput.value = evt.detail.value;
        });
      }

      if (currencyChoices) {
        const currencies = selectedCountry.currencies.map((currency, index) => ({
          value: currency.code,
          label: `${currency.code} ${currency.symbol}`,
          selected: index === 0,
        }));

        currencyChoices.setChoices(currencies, 'value', 'label', true);

        handleDropdownBehavior(currencyChoices, currencies, singleOptionBehaviors, singleOptionBehavior);

        currencyChoices.passedElement.element.addEventListener('change', (evt) => {
          currencyInput.value = evt.detail.value;
        });
      }
    }
  });
};

const initDropdownHeightCalculation = (countryDropdown) => {
  const getDropdown = (el) => {
    const dropdown = el.parentElement.nextElementSibling;

    if (dropdown && dropdown.classList.contains(CLASSES.DROPDOWN_LIST)) {
      return dropdown;
    }

    return null;
  };

  const setDropdownMaxHeight = (dropdown, maxHeight) => {
    const children = Array.from(dropdown.children);
    const maxHeightValue = maxHeight ? `${maxHeight}px` : null;

    dropdown.style.maxHeight = maxHeightValue;
    children.forEach((child) => { child.style.maxHeight = maxHeightValue; });
  };

  countryDropdown.addEventListener('showDropdown', (e) => {
    const dropdown = getDropdown(e.target);

    if (dropdown) {
      const viewportHeight = window.innerHeight;
      const { height, top } = dropdown.getBoundingClientRect();

      if (height + top >= viewportHeight - CONSTANTS.DROPDOWN_LIST_MAX_HEIGHT_OFFSET) {
        const maxHeight = viewportHeight - top - CONSTANTS.DROPDOWN_LIST_MAX_HEIGHT_OFFSET;

        setDropdownMaxHeight(dropdown, maxHeight);
      } else {
        setDropdownMaxHeight(dropdown);
      }
    }
  });

  countryDropdown.addEventListener('hideDropdown', (e) => {
    const dropdown = getDropdown(e.target);

    if (dropdown) {
      setDropdownMaxHeight(dropdown);
    }
  });
};

const initDropdowns = (
  countryDropdown,
  languageDropdown,
  currencyDropdown,
  singleOptionBehaviors,
  singleOptionBehavior,
  containerType,
) => {
  const choicesOptions = {
    shouldSort: true,
    searchEnabled: false,
    itemSelectText: '',
    position: 'bottom',
    sortFn(a, b) {
      return a.label.trim() > b.label.trim() ? 1 : -1;
    },
  };

  const countryOptions = {
    callbackOnCreateTemplates: template => ({
      item: renderCountryOption.bind(null, template, countryItem),
      choice: renderCountryOption.bind(null, template, countryChoice),
    }),
  };

  // eslint-disable-next-line no-unused-vars, max-len
  GLOBALS[containerType].COUNTRY_CHOICES = new Choices(countryDropdown, Object.assign({}, choicesOptions, countryOptions));
  GLOBALS[containerType].LANGUAGE_CHOICES = languageDropdown && new Choices(languageDropdown, choicesOptions);
  GLOBALS[containerType].CURRENCY_CHOICES = currencyDropdown && new Choices(currencyDropdown, choicesOptions);

  // eslint-disable-next-line max-len
  initCountryChange(countryDropdown, GLOBALS[containerType].LANGUAGE_CHOICES, GLOBALS[containerType].CURRENCY_CHOICES, singleOptionBehaviors, singleOptionBehavior);
  initDropdownHeightCalculation(countryDropdown); // Prevent scrolling issues on iOS
};

const initForm = (form) => {
  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    const formError = form.querySelector(SELECTORS.FORM_ERROR);

    if (formError) {
      formError.remove();
    }

    const { content } = await loadContent(form);

    if (content.errorContent) {
      form.innerHTML = content.errorContent + form.innerHTML;
    }

    if (content.redirectUrl) {
      // Ensure the cookie policy gets displayed after the redirect
      window.localStorage.removeItem('previousSid');

      window.location.href = content.redirectUrl;
    }
  });
};

const initGateway = (gatewayContainer) => {
  const header = document.querySelector(HEADER_SELECTORS.HEADER);
  $(gatewayContainer).modal('show');

  $(gatewayContainer).on('shown.bs.modal', () => {
    header.classList.remove('header-z-index');

    /**
     * position: [sticky | fixed] forces the country gateway's z-index not to function properly
     * causing it to act like an element with the lowest z-index
     * */
    header.classList.add('position-static');
  });

  $(gatewayContainer).on('hidden.bs.modal', () => {
    header.classList.add('header-z-index');
    header.classList.remove('position-static');
    document.dispatchEvent(new CustomEvent(EVENTS.UPDATE_HEADER));
  });
};

// After Page Locale Content Load

const afterPageLocaleContentLoad = async (containerType = null) => {
  const gatewayContainer = document.querySelector(SELECTORS.GATEWAY);
  const flyoutContainer = document.querySelector(SELECTORS.CONTAINER);

  if (gatewayContainer && containerType === 'GATEWAY') {
    initGateway(gatewayContainer);
  }

  if (flyoutContainer) {
    initFlyout(flyoutContainer);
    document.dispatchEvent(new CustomEvent('flyout:opened', {
      detail: {
        flyout: flyoutContainer,
        content: flyoutContainer.querySelector('[class*="__container "]'),
      },
    }));
  }

  [gatewayContainer, flyoutContainer].forEach((container) => {
    if (container) {
      const selectionForm = container.querySelector(SELECTORS.FORM);
      const countryDropdown = container.querySelector(SELECTORS.LOCATION_DROPDOWN);
      const languageDropdown = container.querySelector(SELECTORS.LANGUAGE_DROPDOWN);
      const currencyDropdown = container.querySelector(SELECTORS.CURRENCY_DROPDOWN);

      if (selectionForm) {
        initForm(selectionForm);
      }

      if (countryDropdown) {
        clearExistingDropdown();
        initDropdowns(
          countryDropdown,
          languageDropdown,
          currencyDropdown,
          getJSONData(selectionForm, 'selectBehaviors', {}),
          selectionForm.dataset.selectBehavior,
          containerType,
        );
      }
    }
  });
};

// PAGE_LOCALE_LOADER

const loadPageLocale = async () => {
  const pageLocale = document.querySelector(SELECTORS.PAGE_LOCALE_LOADER);
  if (window.innerWidth > 767) {
    if (pageLocale) {
      const { content } = await loadContent(pageLocale, null, null, false);
      pageLocale.innerHTML = content;
      afterPageLocaleContentLoad('GATEWAY');
    }
    const selectorObj = document.querySelector('.country-selector-link');
    selectorObj.addEventListener('click', async () => {
      const { content } = await loadContent(selectorObj, null, null, false);
      pageLocale.innerHTML = content;
      afterPageLocaleContentLoad('CONTAINER');
    });
  }
};

// PAGE_LOCALE_LOADER_MOBILE

const loadPageLocaleMobile = async () => {
  const pageLocaleMobile = document.querySelector(SELECTORS.PAGE_LOCALE_LOADER_MOBILE);
  if (window.innerWidth < 769) {
    if (pageLocaleMobile) {
      const { content } = await loadContent(pageLocaleMobile, null, null, false);
      pageLocaleMobile.innerHTML = content;
    }
    const selectorObj = pageLocaleMobile.querySelector('.country-selector-link');
    selectorObj.addEventListener('click', async () => {
      const { content } = await loadContent(selectorObj, null, null, false);
      pageLocaleMobile.innerHTML = content;
      afterPageLocaleContentLoad('CONTAINER');
    });
  }
};

export default async () => {
  const link = document.querySelector('.globale-selector');
  const countrySelectorLink = document.querySelector('.js-page-locale-loader');
  if (!link && countrySelectorLink) {
    loadPageLocale();
    loadPageLocaleMobile();
  }
};
