const safeDecodeURIComponent = (value) => {
  let decodedValue = value;

  try {
    decodedValue = decodeURIComponent(value);
  } catch (e) {
    // Contains a non-decodable symbol, which means it's already decoded
  }

  return decodedValue;
};

export const appendToUrl = (url, params) => {
  let newUrl = url;
  newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(
    key => `${key}=${encodeURIComponent(params[key])}`,
  ).join('&');

  return newUrl;
};

export const parseQueryString = (queryStringUrl) => {
  const queryString = queryStringUrl.split(/[#?]/)[1] || queryStringUrl;
  const params = queryString.split('&').reduce((acc, pair) => {
    const pairParts = pair.split('=');
    const [paramName, paramValue] = pairParts;

    if (paramName && paramValue) {
      const decodeSafeValue = paramValue.replace(/\+/g, '%20');

      return Object.assign(acc, {
        [paramName]: safeDecodeURIComponent(decodeSafeValue),
      });
    }

    return acc;
  }, {});

  return params;
};

export const removeParamsFromUrl = (url, params) => {
  const [baseUrl, qs] = url.split('?');
  const modifiedQs = (qs || '')
    .split('&')
    .filter(qsPair => params.indexOf(qsPair.split('=')[0]) < 0)
    .join('&');

  return baseUrl + (modifiedQs ? `?${modifiedQs}` : '');
};
