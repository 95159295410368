import 'jspolyfill-array.prototype.find';
import smoothscroll from 'smoothscroll-polyfill';

import $ from 'jquery';
import processInclude from './util';

import { installAjaxHandlers } from './components/ajax';
import * as header from './components/header';
import * as productTile from './search/productTile';
import * as initAddressForms from './components/addressForm';
import bodyScroll from './components/bodyScroll';
import backToTop from './components/backToTop';
import { initForms } from './components/forms';
import initRecaptcha from './components/recaptcha';
import * as intelligentReach from './search/intelligentReach';
import stickyHeaderScroll from './components/stickyHeaderScroll';
import validateInput from './components/validateInput';
import addToBagRecommendations from './components/addToBagRecommendation';

import '~base';

installAjaxHandlers();

$(document).ready(() => {
  processInclude(header);
  processInclude(productTile);
  processInclude(initAddressForms);
  processInclude(backToTop);
  processInclude(bodyScroll);
  processInclude(initForms);
  processInclude(initRecaptcha);
  processInclude(intelligentReach);
  processInclude(stickyHeaderScroll);
  processInclude(validateInput);
  processInclude(addToBagRecommendations);

  smoothscroll.polyfill();
});
