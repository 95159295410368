import { loadContent, CONTENT_TYPES } from './fetchutils';

/**
 * Utility function to execute a selector lookup on the document,
 * an element or an array of elements
 *
 * @param  {string} selector The selector to find
 * @param  {Element|Element[]} [el=document] The element(s) relative to which to execute the lookup.
 *                                           -If no parameter is provided, the page document is used.
 *                                           If an array is provided, the query is executed on each of them,
 *                                           and the results are flattened.
 * @return {Node[]} The nodes found by the query
 */
export const find = (selector, el) => {
  let safeEl = el;
  let childrenArr = [];

  if (!safeEl) {
    safeEl = document;
  } else if (typeof safeEl === 'string') {
    safeEl = Array.prototype.slice.call(document.querySelectorAll(safeEl));
  }

  if (Array.isArray(safeEl)) {
    childrenArr = safeEl
      .map(parentEl => parentEl.querySelectorAll(selector))
      .reduce((acc, children) => acc.concat(Array.prototype.slice.call(children)), []);
  } else {
    childrenArr = Array.prototype.slice.call(safeEl.querySelectorAll(selector));
  }

  return childrenArr;
};

/**
 * Convenience function to find all form fields in an element.
 *
 * @see    find
 * @param  {Element|Element[]} [el] The element(s) relative to which to execute the lookup.
 * @return {Node[]} The form field nodes found by the query
 */
export const findFormFields = el => find('input, select, textarea, output', el);

/**
 * Determines whether a form element should ne included in its values hash
 *
 * @param  {Element} element The element to check
 * @return {boolean}         True if it should be included
 */
const useElement = (element) => {
  const { disabled, checked } = element;
  const selection = element.type === 'radio' || element.type === 'checkbox';

  return !disabled && (!selection || checked);
};

/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
export const getFormValuesObject = parent => findFormFields(parent).reduce((data, element) => {
  if (useElement(element)) {
    data[element.name] = element.value;
  }
  return data;
}, {});


/**
 * Populates the input attribute values of the form fields in the provided parent
 * so that they match their respective property values
 *
 * @param {HTMLFormControlsCollection} parent The parent of the elements to refresh
 */
export const refreshAttributes = (parent) => {
  findFormFields(parent).forEach((field) => {
    if (field.tagName.toLowerCase() === 'select') {
      const { value } = field;
      const selectedOption = Array.from(field.options).find(option => option.getAttribute('selected'));
      const valueOption = Array.from(field.options).find(option => option.value === value);

      if (selectedOption) {
        selectedOption.removeAttribute('selected');
      }

      if (valueOption) {
        valueOption.setAttribute('selected', 'selected');
      }
    } else {
      field.setAttribute('value', field.value);
    }

    if (field.checked) {
      field.setAttribute('checked', 'checked');
    } else {
      field.removeAttribute('checked');
    }

    if (field.disabled) {
      field.setAttribute('disabled', 'disabled');
    } else {
      field.removeAttribute('disabled');
    }
  });
};

/**
 * Loads in any remote includes that were returned in content in JSON responses as 'wainclude'
 */
export const populateRemoteIncludes = async () => {
  const remoteIncludes = Array.from(document.querySelectorAll('wainclude'));

  const operations = remoteIncludes.map(remoteInclude => loadContent(remoteInclude));
  const results = await Promise.all(operations);

  results.forEach((result, i) => {
    const { content, type } = result;

    if (type === CONTENT_TYPES.TEXT) {
      remoteIncludes[i].outerHTML = content;
    }
  });
};

export const visible = el => el && !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);

/**
 * Replaces the passed in component with the updated content
 *
 * @param  {string} selector Selector for the element to replace
 * @param  {string} content  The HTML content to update the element with
 */
export const updateComponent = async (selector, content) => {
  const components = Array.from(document.querySelectorAll(selector));

  const operations = components.map((component) => {
    if (component && visible(component)) {
      if (content && content.length) {
        component.outerHTML = content;

        return populateRemoteIncludes();
      }

      component.remove();
    }

    return Promise.resolve();
  });

  await Promise.all(operations);
};

/**
 * Creates an event listener using the provided values, removing and potential duplicates beforehan
 *
 * @param {EventTarget} el      The target to add a listener to
 * @param {string}      evt     The event to listen for
 * @param {Function}    handler The event handler to attach
 */
export const addUniqueEventListener = (el, evt, handler) => {
  el.removeEventListener(evt, handler);
  el.addEventListener(evt, handler);
};

/**
 * Checks whether the current window width puts the user in the MD style breakpoint
 *
 * @return {boolean} True if MD
 */
export const isMD = () => window.matchMedia('(min-width: 769px)').matches;

/**
 * Checks whether the current window width puts the user in the LG style breakpoint
 *
 * @return {boolean} True if LG
 */
export const isLG = () => window.matchMedia('(min-width: 992px)').matches;

/**
 * Checks whether the current window width puts the user in the XL style breakpoint
 *
 * @return {boolean} True if XL
 */
export const isXL = () => window.matchMedia('(min-width: 1200px)').matches;

export const isIOS = () => navigator.userAgent.match(/(iPhone|iPod|iPad)/i);

export const innerHeight = (el) => {
  const style = window.getComputedStyle(el);

  const height = parseInt(style.height, 10) || 0;
  const paddingTop = parseInt(style.paddingTop, 10) || 0;
  const paddingBottom = parseInt(style.paddingBottom, 10) || 0;
  const borderTopWidth = parseInt(style.borderTopWidth, 10) || 0;
  const borderBottomWidth = parseInt(style.borderBottomWidth, 10) || 0;

  return height - paddingTop - paddingBottom - borderTopWidth - borderBottomWidth;
};

/**
 * Determines if the current page was navigated to via back or forward navigation
 *
 * @return {boolean} True if back/forward was used
 */
export const isBackForwardNavigation = () => {
  const LEGACY_BACKFW_TYPE = window.PerformanceNavigation && window.PerformanceNavigation.TYPE_BACK_FORWARD;

  const perf = window.performance;
  const legacyNavType = perf && perf.navigation && perf.navigation.type;
  const navEntry = perf && perf.getEntriesByType && perf.getEntriesByType('navigation')[0];

  const navEntryBackFw = navEntry && navEntry.type === 'back_forward';
  const legacyNavBackFw = legacyNavType && legacyNavType === LEGACY_BACKFW_TYPE;

  return !!(navEntryBackFw || legacyNavBackFw);
};

/**
 * @typedef {Object} ElementOffsets
 * @property {number} top  The top offset
 * @property {number} left The left offset
 */
/**
 * Calculates the offsets of the provided element from the document edges
 *
 * @param {Element} el The element to calculate for
 * @return {ElementOffsets}
 */
export const getElementOffset = (el) => {
  let top = 0;
  let left = 0;
  let element = el;

  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top,
    left,
  };
};

export * from '~base';
