/**
 * Get the storefront config provided via script tag.
 *
 * Depending on the configuration it uses Redux or read it directly
 */
let app;
if (ENABLE_REDUX) {
  app = require('./appConfig.redux').default; // eslint-disable-line global-require
} else {
  app = require('./appConfig.plain').default; // eslint-disable-line global-require
}

const appConfig = app;
export default appConfig;
