import { onEvent, updateComponent } from '../util/domutils'; // eslint-disable-line import/named
import { loadContent, CONTENT_TYPES } from '../util/fetchutils';

export const ADDRESS_FORM_EVENTS = {
  REFRESH: 'country-form:refresh',
  POPULATE: 'address-fields:populate',
  SUBMIT: 'country-form:submit',
  UPDATE: 'address-fields:update',
};

const SELECTORS = {
  FIELDS: '.address-fields',
  ROW: '.address-fields__row',
  COUNTRY: '[name$="_country"]',
};

export const initAddressForms = () => {
  onEvent(document, 'change', SELECTORS.COUNTRY, async (e) => {
    const field = e.delegateTarget;
    const fieldRow = field.closest(SELECTORS.ROW);

    if (fieldRow && fieldRow.dataset.action) {
      const { content, type } = await loadContent(field.form, fieldRow.dataset.action);

      if (type === CONTENT_TYPES.JSON && content.csrfError) {
        window.location.href = content.redirectUrl;
      } else if (type === CONTENT_TYPES.TEXT) {
        await updateComponent(SELECTORS.FIELDS, content);
      }

      document.dispatchEvent(new CustomEvent(ADDRESS_FORM_EVENTS.REFRESH));
    }
  });
};

export const handleUppercasedFields = () => {
  onEvent(document, 'keyup', '[data-uppercase="true"]', (e) => {
    e.delegateTarget.value = e.delegateTarget.value.toUpperCase();
  });
};
