const CALLBACKS = [];

const handleAjaxResponse = (url, responseContent) => {
  CALLBACKS.forEach((cb) => {
    if (typeof cb === 'function') {
      cb(responseContent);
    }

    if (
      typeof cb === 'object'
      && typeof cb.func === 'function'
      && (!cb.urlFilter || url.indexOf(cb.urlFilter) >= 0)
    ) {
      cb.func(responseContent);
    }
  });
};

/**
 * Extend the AJAX mechanisms that get used to allow for handling logic after they;ve been completed
 * (i.e. jQuery's ajaxComplete event, but using vanilla JS is sooo much better for reasons)
 */
export const installAjaxHandlers = () => {
  const originals = {
    open: XMLHttpRequest.prototype.open,
    fetch: window.Response && window.Response.prototype && {
      blob: window.Response.prototype.blob,
      json: window.Response.prototype.json,
      text: window.Response.prototype.text,
    },
  };

  // Attach to all calls to the fetch response's handlers
  if (originals.fetch) {
    Object.keys(originals.fetch).forEach((originalName) => {
      const original = originals.fetch[originalName];

      if (original) {
        window.Response.prototype[originalName] = function (...args) {
          return original.apply(this, args).then((responseContent) => {
            handleAjaxResponse(this.url, responseContent);
            return responseContent;
          });
        };
      }
    });
  }

  // Attach to all calls using XMLHttpRequest (which would include jQuery calls in the base)
  if (originals.open) {
    XMLHttpRequest.prototype.open = function (...args) {
      this.addEventListener('readystatechange', function () {
        if (this.readyState === XMLHttpRequest.DONE) {
          handleAjaxResponse(this.responseURL, this.responseText);
        }
      }, false);

      originals.open.apply(this, args);
    };
  }
};

export const functions = {
  // Attaches a callback which will be executed when an AJAX request completes.
  // Either a function or an object can be provided:
  //  - Functions get called after each AJAX request completes
  //    and receive response content as a parameter (be it text or a JSON object)
  //  - Objects can contain a urlFilter and a func field; if the former is a string,
  //    that is contained in an AJAX request's url, then the latter is treated as
  //    a function and is used as a callback
  onComplete(cb) {
    if (CALLBACKS.indexOf(cb) < 0) {
      CALLBACKS.push(cb);
    }
  },
};
