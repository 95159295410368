import $ from 'jquery';
import { loadContent, fetchOptionsGet } from '../util/fetchutils'; // eslint-disable-line import/named
import { outerHeightWithMargin, onEvent, isLG } from '../util/domutils'; // eslint-disable-line import/named
import { initFlyout, EVENTS as FLYOUT_EVENTS } from './flyoutUnderHeader';
import { EVENTS as HEADER_EVENTS } from './header';

const CONSTANTS = {
  TOGGLE_CLASS: 'show',
  MIN_BODY_HEIGHT: 75,
  HOVER_TIMEOUT: 400,
  COUNT_UPDATE_DISPLAY_TIME: 3000,
};

const CLASSES = {
  LINK_HIGHLIGHTED: 'minicart-link--active',
};

const SELECTORS = {
  PARENT: '.minicart',
  LINK: '.minicart-link',
  CONTAINER: '.minicart__pane',
  CONTAINER_INNER: '.minicart__container',
  CONTENT: '.minicart__content',
  HEADER: '.minicart__header',
  BODY: '.minicart__body',
  FOOTER: '.minicart__footer',
  CART_BTN: '.checkout-btn',
  CLOSE_BUTTON: '.minicart__close-btn',
  OPEN_BUTTON: '.header-element.minicart',
  TOTAL_QUANTITY: '.minicart .minicart-quantity',
  TOTAL_VALUE: '.minicart .minicart-grand-total',
};

const NEWPDP = document.querySelector('.new-pdp');
const headerEle = document.querySelector('.compact-header');

/**
 * After setting the total height of the minicart in regards to the page header,
 * calculates and sets the height of the minicart body's section
 * so that its footer gets stuck to the bottom of the content container
 *
 * @param  {Element} container The minicart container
 * @param  {Number} [pageHeaderHeight=<calculated height>] The height of the header to align against
 * @return {Number} The calculated max height for the body
 */
const setMiniCartBodyHeights = (container) => {
  const content = container.querySelector(SELECTORS.CONTENT);
  const header = container.querySelector(SELECTORS.HEADER);
  const body = container.querySelector(SELECTORS.BODY);
  const footer = container.querySelector(SELECTORS.FOOTER);

  if (header && body && footer) {
    const totalHeight = outerHeightWithMargin(content);
    const headerHeight = outerHeightWithMargin(header);
    const footerHeight = outerHeightWithMargin(footer);
    const bodyMaxHeight = totalHeight - headerHeight - footerHeight;

    body.style.maxHeight = `${bodyMaxHeight}px`;

    return bodyMaxHeight;
  }

  return null;
};

/**
 * Checks if the target of the passed in event is one of the elements
 * which should cause the minicart to be closed when clicked on
 *
 * @param  {Event}   e The event to check
 * @return {Boolean} True if the event represents a minicart close click
 */
const isClickOnCloseTrigger = (e) => {
  const closeBtn = document.querySelector(SELECTORS.CLOSE_BUTTON);

  return (!e.target.closest(SELECTORS.CONTAINER) && !e.target.closest(SELECTORS.PARENT))
        || e.target.closest(SELECTORS.CLOSE_BUTTON)
        || e.target === closeBtn;
};

const showFlyout = (container) => {
  // Trigger global events to close any other open flyouts and position the minicart correctly
  document.dispatchEvent(new CustomEvent(HEADER_EVENTS.UPDATE_HEADER));

  document.dispatchEvent(new CustomEvent(FLYOUT_EVENTS.FLYOUT_OPEN, {
    detail: {
      flyout: container,
    },
  }));

  // Display the flyout
  container.classList.add(CONSTANTS.TOGGLE_CLASS);
};

const displayFlyout = async (container, showFlyoutImmediately = true) => {
  const toggle = document.querySelector(SELECTORS.PARENT);
  const { actionUrl } = toggle.dataset;
  const innerContainer = container.querySelector(SELECTORS.CONTAINER_INNER);
  const body = document.querySelector('body');
  body.style.borderRightWidth = '17px';
  if (showFlyoutImmediately) {
    // Clear the stale minicart content before showing the flyout, so only the spinner is visible
    innerContainer.innerHTML = '';

    // Start the loading indicator in the flyout
    $(innerContainer).spinner().start();

    showFlyout(container);
  }

  // Load the minicart content
  const response = await fetch(actionUrl, fetchOptionsGet());
  const data = await response.text();

  // Populate the fresh minicart content in the flyout
  innerContainer.innerHTML = data;
  if (NEWPDP && headerEle && !isLG()) {
    headerEle.classList.add('active-flyout');
  }
  // Calculate the heights of the minicart elements so the footer is fixed at the bottom
  const bodyHeight = setMiniCartBodyHeights(container);

  if (!showFlyoutImmediately && bodyHeight && bodyHeight >= CONSTANTS.MIN_BODY_HEIGHT) {
    showFlyout(container);
  }

  // Remove the loading indicator
  $(innerContainer).spinner().stop();
};

const closeFlyout = (container) => {
  const body = document.querySelector('body');
  body.style.borderRightWidth = '';
  container.classList.remove(CONSTANTS.TOGGLE_CLASS);
  if (NEWPDP && headerEle && !isLG()) {
    headerEle.classList.remove('active-flyout');
  }
  document.dispatchEvent(new CustomEvent(FLYOUT_EVENTS.FLYOUT_CLOSE, {
    detail: {
      flyout: container,
    },
  }));
};

/**
 * Initializes the logic which controls the displaying and closing of the minicart
 *
 * @param {Element} container The minicart container
 */
const initMiniCartToggle = (container) => {
  let miniCartDisplayTimeout;

  document.addEventListener('click', (e) => {
    if (container.classList.contains(CONSTANTS.TOGGLE_CLASS)) {
      // hide the minicart on click event outside the container or cross-icon click
      if (isClickOnCloseTrigger(e)) {
        closeFlyout(container);
      }
    }
  });

  onEvent(document, 'touchstart', SELECTORS.PARENT, (e) => {
    e.target.dataset.touch = true;
  });

  onEvent(document, 'mouseover', SELECTORS.PARENT, (e) => {
    const qtyContainer = document.querySelector(SELECTORS.TOTAL_QUANTITY);

    const touchEnabled = e.target.dataset.touch === 'true';
    const inMiniCart = e.target.closest(SELECTORS.CONTAINER);
    const miniCartShown = container.classList.contains(CONSTANTS.TOGGLE_CLASS);
    const hasItems = parseInt(qtyContainer.dataset.qt, 10) > 0;

    if (!touchEnabled && !inMiniCart && !miniCartShown && hasItems) {
      miniCartDisplayTimeout = setTimeout(() => {
        // show the minicart
        displayFlyout(container);
      }, window.miniCartHoverTimeout || CONSTANTS.HOVER_TIMEOUT);
    }

    // Prevent touches from stopping later hovers from working, e.g. on touch-screen laptops
    e.target.dataset.touch = false;
  });

  onEvent(document, 'mouseout', SELECTORS.PARENT, () => {
    clearTimeout(miniCartDisplayTimeout);
  });
};

/**
 * Handles changes to the total number of items in the cart
 */
const updateMiniCartTotal = (container) => {
  let closeAfterChangeTimeout;

  $(SELECTORS.PARENT).on('count:update', async (event, data) => {
    // update minicart link quantity and total in the header menu
    const cart = data.cart || data.basket || data;
    const countValue = cart.numItems;
    const totalValue = cart.totals.grandTotal;
    const totalAvailable = totalValue && totalValue !== '-' && totalValue !== 'N/A';

    const link = document.querySelector(SELECTORS.LINK);
    const qtyContainer = document.querySelector(SELECTORS.TOTAL_QUANTITY);
    const totalContainer = document.querySelector(SELECTORS.TOTAL_VALUE);
    const pageAction = document.querySelector('.page').dataset.action;
    const isFromCart = pageAction === 'Cart-Show';
    qtyContainer.innerHTML = countValue;
    qtyContainer.dataset.qt = countValue;

    totalContainer.innerHTML = totalAvailable ? totalValue : '';
    totalContainer.dataset.total = totalValue;

    if (countValue > 0 && !isFromCart && $('.ATBOverlayEnable').length < 1) {
      await displayFlyout(container, false);

      closeAfterChangeTimeout = setTimeout(() => {
        closeFlyout(container);
      }, CONSTANTS.COUNT_UPDATE_DISPLAY_TIME);
    }

    link.classList.toggle(CLASSES.LINK_HIGHLIGHTED, countValue > 0);
  });

  onEvent(document, 'mouseover', SELECTORS.CONTAINER_INNER, () => {
    clearTimeout(closeAfterChangeTimeout);
  });
};

const updateMiniCart = async () => {
  const minicart = document.querySelector(SELECTORS.PARENT);

  if (minicart) {
    const { content } = await loadContent(minicart, null, null, false);

    minicart.innerHTML = content;

    const container = document.querySelector(SELECTORS.CONTAINER);

    if (container) {
      updateMiniCartTotal(container);

      if (container.dataset.initFlyoutUnderHeader) {
        initFlyout(container, setMiniCartBodyHeights, CONSTANTS.TOGGLE_CLASS);
      }

      initMiniCartToggle(container);
    }
  }
};

export default async () => {
  updateMiniCart();
};
