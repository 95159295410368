/* eslint-disable no-undef */
/* eslint-disable prefer-template */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable jquery/no-data */
/* eslint-disable jquery/no-each */
/**
 * This is the main entry point for the brand.
 */

import $ from 'jquery';

window.renderCloudinaryGalleryWidget = function () {
  let imgUrls;
  let galleryOptions;
  const $cldEl = $('.cloudinary-data-container');
  const cloudinaryObj = $cldEl.data('cloudinary');

  if (cloudinaryObj) {
    if (cloudinaryObj.galleryEnabled && typeof cloudinary !== 'undefined') {
      galleryOptions = cloudinaryObj.images.galleryWidget.options;
      window.cldGallery = cloudinary.galleryWidget(galleryOptions); // eslint-disable-line no-undef
      cldGallery.render(); // eslint-disable-line no-undef
    } else if (cloudinaryObj.images && cloudinaryObj.images.imageURLs) {
      imgUrls = cloudinaryObj.images.imageURLs || '';
    }
  }

  // removing custom code below as feature was introduced to the Gallery base code in the last v1.1.34

  // $(document).on('click', '#cld-gallery', function (event) {
  //   setTimeout(() => {
  //     let tryAgain = false; // Retry checking is the zoom-in rendered
  //     // Avoiding the mutationObserver due to the DOM complexity
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const el of document.getElementsByClassName(event.target.className)) {
  //       if (el.style && el.style.cursor === 'zoom-in') {
  //         el.dispatchEvent(new PointerEvent('pointerdown'));
  //         el.dispatchEvent(new PointerEvent('pointerup'));
  //         return;
  //       }
  //       if (el.style && el.style.cursor === 'zoom-out') {
  //         tryAgain = true;
  //       }
  //     } // for
  //     if (tryAgain) {
  //       event.target.click();
  //     }
  //   }, 50); // setTimeout
  // });

  return imgUrls;
};

window.renderCloudinarySetGalleryWidgets = function () {
  let cldObj;
  let cldSetImages;

  $('.cloudinary-set-data-container').each(function () {
    cldObj = $(this).data('cloudinary');
    cldSetImages = $(this).data('cloudinary-set-images');

    if (cldObj && cldObj.isEnabled && cldSetImages && cldSetImages.galleryWidget
      && cldObj.galleryEnabled && typeof cloudinary !== 'undefined') {
      window.cldGallery = cloudinary.galleryWidget(cldSetImages.galleryWidget.options); // eslint-disable-line no-undef
      cldGallery.render(); // eslint-disable-line no-undef
    }
  });
};

window.renderCloudinaryVideoPlayer = function () {
  let videoPlayerID;
  let cldObj;
  let cld;
  let player;
  const cldURLs = [];

  $('.cloudinary-data-container').each(function () {
    cldObj = $(this).data('cloudinary');
    videoPlayerID = $(this).data('cloudinary-video-player-id');

    if (cldObj && cldObj.video && cldObj.video.videoURL
      && cldObj.video.videoURL !== '' && cldObj.video.videoURL !== 'null') {
      if (cldObj.videoPlayerEnabled && typeof cloudinary !== 'undefined') {
        cld = cloudinary.Cloudinary.new({ cloud_name: cldObj.cloudName }); // eslint-disable-line no-undef
        player = cld.videoPlayer('cld-video-player' + (videoPlayerID ? '-' + videoPlayerID : ''),
          cldObj.video.widgetOptions);
        player.source(cldObj.video.videoURL, {}).play();
        player.transformation(cldObj.video.widgetOptions.transformations);
      } else {
        cldURLs.push(cldObj.video.videoURL);
      }
    }
  });
  return cldURLs;
};

window.makeCloudinaryImagesResponsive = function () {
  const $cldResponsiveImgTags = $('.cld-responsive');
  if (typeof cloudinary !== 'undefined') {
    if ($cldResponsiveImgTags && $cldResponsiveImgTags.length > 0) {
      window.cldObj = window.cldObj || cloudinary.Cloudinary.new(); // eslint-disable-line no-undef
      window.cldObj.responsive(); // eslint-disable-line no-undef
    }
  }
};

export default () => {
  $(document).ready(function () {
    window.renderCloudinaryGalleryWidget();
    window.renderCloudinaryVideoPlayer();
    window.renderCloudinarySetGalleryWidgets();
    window.makeCloudinaryImagesResponsive();
  });
  $(document).on('product:afterAttributeSelect', function () {
    window.makeCloudinaryImagesResponsive();
  });
  $(document).on('click', '.product-tile__swatch', function () {
    // eslint-disable-next-line jquery/no-closest
    const tile = $(this).closest('.product-tile');
    // eslint-disable-next-line jquery/no-find
    const img = $(tile).find('.tile-image');
    // eslint-disable-next-line jquery/no-attr
    $(img).attr('src', $(this).data('image'));
    $(img).attr('srcset', $(this).data('srcset'));
    $(img).attr('sizes', $(this).data('sizes'));
    window.makeCloudinaryImagesResponsive();
  });
};
