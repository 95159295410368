import $ from 'jquery';
import { EVENTS as WISHLIST_EVENTS } from '../product/wishlist';

const SELECTORS = {
  HEADER_COUNTER: '.wishlist-quantity',
};

export default () => {
  const wishlistEmpty = document.querySelector('.header-element__icon > .wishlist-empty');
  const wishlistFilled = document.querySelector('.header-element__icon > .wishlist-filled');
  const productCounter = document.querySelector(SELECTORS.HEADER_COUNTER);

  function unMarkWishlist() {
    if (wishlistFilled && parseInt(productCounter.innerHTML, 10) === 0) {
      if (wishlistFilled && !wishlistFilled.classList.contains('d-none')) {
        wishlistFilled.classList.add('d-none');
      }
      if (wishlistEmpty.classList.contains('d-none')) {
        wishlistEmpty.classList.remove('d-none');
      }
    }
  }

  function markWishlistFilled() {
    if (wishlistFilled) {
      if (wishlistFilled && wishlistFilled.classList.contains('d-none')) {
        wishlistFilled.classList.remove('d-none');
      }
      if (!wishlistEmpty.classList.contains('d-none')) {
        wishlistEmpty.classList.add('d-none');
      }
    }
  }

  $(document).on(WISHLIST_EVENTS.WISHLIST_ADD, () => {
    markWishlistFilled();
    if (productCounter) {
      productCounter.innerHTML = parseInt(productCounter.innerHTML, 10) + 1;
    }
  });

  $(document).on(WISHLIST_EVENTS.WISHLIST_REMOVE, () => {
    if (productCounter) {
      const newCount = parseInt(productCounter.innerHTML, 10) - 1;
      productCounter.innerHTML = newCount > 0 ? newCount : 0;
    }
    unMarkWishlist();
  });

  $(window).on('load', () => {
    if (productCounter && parseInt(productCounter.innerHTML, 10) > 0) {
      markWishlistFilled();
    } else {
      unMarkWishlist();
    }
  });
};
