import { getElementOffset, isLG } from './domutils';

/**
 * Allows for consistently scrolling the viewport to a specified element
 *
 * @param {string|Element|number} targetSelector Selector for the element or Y offset to scroll to
 */
export default (targetSelector, smooth = true) => {
  let scrollTarget = targetSelector;

  if (typeof targetSelector === 'string') {
    scrollTarget = document.querySelector(targetSelector);
  }

  if (scrollTarget && scrollTarget instanceof Element) {
    let top = getElementOffset(scrollTarget).top - 100;
    if (isLG()) {
      top = getElementOffset(scrollTarget).top - 175;
    }

    window.scroll({
      top,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }

  if (typeof scrollTarget === 'number' && !Number.isNaN(scrollTarget)) {
    window.scroll({
      top: scrollTarget,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }
};
