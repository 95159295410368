import { onEvent, getJSONData } from '../util/domutils';

const getDataLayer = () => {
  let dataLayer = [];

  if (window.googleAnalytics && window[window.googleAnalytics.DATA_LAYER_NAME]) {
    dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];
  }

  return dataLayer;
};

const getCurrency = (dataLayer) => {
  const currencyEvt = dataLayer.find(evt => evt.currency);

  if (currencyEvt) {
    return currencyEvt.currency;
  }

  return null;
};

const getProductList = (trigger) => {
  const listContainers = {
    'recommendation - CTL': '#looksGreatWith',
    'recommendation - YMAL': '#recommendations',
    PDP: '[data-action="Product-Show"]',
    'PLP - category': '[data-action="Search-Show"][data-querystring*="cgid="]',
    'PLP - search results': '[data-action="Search-Show"][data-querystring*="q="]',
    'PLP - other': '[data-action="Search-Show"]',
    'Recently viewed': '.product-detail__recently-viewed',
    'cart - wishlist': '.cart-wishlist-section',
    wishlist: '[data-action="Wishlist-Show"]',
  };

  return Object.keys(listContainers).find(listName => trigger.closest(listContainers[listName])) || 'other';
};

const getProductObject = (trigger) => {
  const trackingEl = trigger.closest('[data-tracking]');

  if (trackingEl) {
    return getJSONData(trackingEl, 'tracking', null);
  }

  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const productClick = () => {
  ['click', 'auxclick'].forEach((evt) => {
    onEvent(document, evt, '.product-tile .link', (e) => {
      const dataLayer = getDataLayer();
      const productObj = getProductObject(e.delegateTarget);
      // Only a standard left click needs to be aborted and handled with a callback;
      // Scroll/Middle button clicks open a new tab, so they will be tracked by the current tab; same for Ctrl + Click
      // Right clicks must open a context menu, so no way to track those
      const standardClick = evt === 'click' && e.button === 0 && !e.ctrlClick;
      const auxilliaryClick = evt === 'auxclick' && e.button >= 2;

      if (auxilliaryClick) {
        // Auxilliary buttons usually don't interact with links, so no need to track them
        return;
      }

      if (productObj) {
        try {
          const payload = {
            event: 'eec.productClick',
            ecommerce: {
              currencyCode: getCurrency(dataLayer),
              click: {
                actionField: {
                  list: getProductList(e.delegateTarget),
                },
                products: [productObj],
              },
            },
          };

          if (standardClick) {
            payload.eventCallback = () => { window.location.href = e.delegateTarget.href; };
            payload.eventTimeout = 300;
          }

          dataLayer.push(payload);
        } catch {
          return;
        }

        if (standardClick) {
          e.preventDefault();

          setTimeout(() => {
            window.location.href = e.delegateTarget.href;
          }, 1000);
        }
      }
    });
  });
};
