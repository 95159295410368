import $ from 'jquery';
import { find, findFormFields, visible } from '../../util/domutils';

export const COLLAPSE_EVENTS = {
  FORMS_STATE_CHANGED: 'collapse:forms:change',
};

const SELECTORS = {
  COLLAPSE: '.collapse',
  TABS: '[data-toggle="tab"]',
};

/**
 * Sets the enabled/disabled state of all form elements found inside the element(s)
 * represented by the selector
 *
 * @param  {Element[]} rootEls The elements to toggle the fields in
 * @param  {boolean}   enabled Whether to enable or disable the form fields
 */
function toggleFormElementsState(rootEls, enabled) {
  rootEls.forEach((root) => {
    findFormFields(root).forEach((el) => {
      const parent = el.closest('div');
      const hiddenInput = el.type === 'hidden';
      const label = document.querySelector(`[for="${el.id}"]`);
      const activeHiddenInput = hiddenInput && visible(parent);
      const activeToggle = label && visible(label);

      const elVisible = visible(el) || el.dataset.choice || activeHiddenInput || activeToggle;

      el.disabled = !el.dataset.forceEnabled && (!elVisible || !enabled);
    });

    if (enabled) {
      // Handle nested collapse and tab elements
      find(SELECTORS.TABS, root).forEach(el => toggleTabPanelFormElementsState.call(el));
      find(SELECTORS.COLLAPSE, root).forEach(el => toggleCollapseFormElementsState.call(el));
    }
  });
}

/**
 * Sets the enabled/disabled state of all form elements found inside the
 * tabpanel of the Bootstrap tab element passed in as a calling context
 *
 * @this {Element} el The element in whose tabpanel to toggle the fields in
 */
function toggleTabPanelFormElementsState() {
  const targets = document.querySelectorAll(this.hash);
  const targetsArr = Array.prototype.slice.call(targets);

  toggleFormElementsState(targetsArr, this.classList.contains('active'));
}

/**
 * Sets the enabled/disabled state of all form elements found inside the
 * Bootstrap collapse element passed in as a calling context
 *
 * @this {Element} The element to toggle the fields in
 */
function toggleCollapseFormElementsState() {
  toggleFormElementsState([this], this.classList.contains('show'));
}

/**
 * Finds all elements that correspond to the passed in selector
 * and calls the provided callback for each of them
 *
 * @param  {string}   selector The selector to use
 * @param  {Function} callback The callback to execute
 */
function handleExistingElements(selector, callback) {
  // Reversing the selected elements is the cheapest way to ensure that nested
  // tabs and collapses are handled depth-first
  Array.from(document.querySelectorAll(selector)).reverse().forEach(el => callback.call(el));

  document.dispatchEvent(new CustomEvent(COLLAPSE_EVENTS.FORMS_STATE_CHANGED));
}

/**
 * Initializes listeners that handle the state of form elements inside
 * Bootstrap collapse elements or tab panels
 *
 * @param {string} type The type of Bootstrap element to handle
 */
function initElements(type) {
  let selector;
  let callback;

  switch (type) {
    case 'tab':
      selector = SELECTORS.TABS;
      callback = toggleTabPanelFormElementsState;
      break;

    case 'collapse':
      selector = SELECTORS.COLLAPSE;
      callback = toggleCollapseFormElementsState;
      break;

    default:
  }

  if (selector && callback) {
    // Handle elements currently on the page
    handleExistingElements(selector, callback);

    // Listen for the type's toggle events from user interaction
    $(document).on(`shown.bs.${type} hidden.bs.${type}`, selector, function () {
      callback.call(this);

      document.dispatchEvent(new CustomEvent(COLLAPSE_EVENTS.FORMS_STATE_CHANGED));
    });

    // Handle elements that get added as a result of AJAX calls
    const observer = new MutationObserver(handleExistingElements.bind(null, selector, callback));
    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  }
}

/**
 * Initializes listeners that handle the state of form elements inside Bootstrap tab panel elements
 */
function initTabs() {
  initElements('tab', SELECTORS.TABS, toggleTabPanelFormElementsState);
}

/**
 * Initializes listeners that handle the state of form elements inside Bootstrap collapse elements
 */
function initCollapse() {
  initElements('collapse', SELECTORS.COLLAPSE, toggleCollapseFormElementsState);
}

/**
 * Ensure that any form elements inside collapse or tabpanel containers
 * get disabled when their parents get hidden and vice-versa
 */
export const toggleFormElementStates = () => {
  initTabs();
  initCollapse();
};
