import $ from 'jquery';
import processInclude from '../util';
import scrollTo from '../util/scrollTo';
import { updateComponent } from '../util/domutils';
import clientSideValidation from '../components/clientSideValidation';
import { loadFormErrors } from './formErrors';

export const SELECTORS = {
  HEADER: '.checkout-header',
  CONTENT: '.checkout',
  STEP_FORM: '.checkout-step__form',
  PROGRESS_STEP: '.checkout__progress-step-number',
  SHIPPING_FORM: '.checkout-shipping__form',
  BILLING_FORM: '.checkout-billing__form',
  SUMMARY_FORM: '.checkout-summary__form',
  STEP_CONTENT: '.page',
  SHIPPING_TYPE: {
    TYPE: '.checkout-shipping__type',
    CURRENT_TYPE: '.checkout-shipping__type.active',
  },
  SAVED_ADDRESS: {
    SHIPMENT_SELECTOR: '.saved-addresses',
    SAVED_ADDRESS_CONTAINER: '.saved-addresses__pane',
    SAVED_ADDRESS_INPUT: '.saved-addresses__address-input',
  },
  SHIPPING_ADDRESS: {
    FIELDS: '.checkout-shipping__address',
    NEW_FIELDS: '.saved-addresses__new-fields',
    SAVE_TOGGLE: '.checkout-shipping__shipment__save-address',
    get LINE1() { return `${this.FIELDS} #addressOne`; },
    get STATE() { return `${this.FIELDS} #stateCode`; },
    get CITY() { return `${this.FIELDS} #city`; },
    get POSTAL_CODE() { return `${this.FIELDS} #postalCode`; },
  },
  SHIPPING_METHODS: {
    CONTAINER: '.checkout-shipping__method-list',
    METHOD: '.checkout-shipping__method',
    METHOD_INPUT: '.checkout-shipping__method-input',
  },
  GIFT: {
    CONTAINER: '.checkout-shipping__gift',
    INPUT: '.checkout-shipping__gift-input',
    MESSAGE_INPUT: '.checkout-shipping__gift-message',
    MESSAGE_TEXT_AREA: '#giftMessage',
    SYMBOLS_LEFT: '.checkout-shipping__symbols-left-count',
    GIFT_PACKAGING_CHECKBOX: '.checkout-shipping__gift-content',
  },
  BILLING_ADDRESS: {
    CARD: '.checkout-billing__address-card',
    SAME_AS_SHIPPING: '.checkout-billing__copy-shipping-input',
  },
  PAYMENT_METHODS: {
    GIFT_CARD_FORM: '.checkout-billing__gift-card-form',
    CREDIT_CARD_FORM: '.checkout-billing__credit-card-form',
    INPUT: '.checkout-billing__payment-input',
    CONTENT: '.checkout-billing__payment-content',
    CREDIT_CARD: {
      DATE: '#expirationDate',
      MONTH: '#expirationMonth',
      YEAR: '#expirationYear',
      NEW_CVV: '.checkout-billing__cvv--new',
      SAVED_CVV: '.checkout-billing__cvv--saved',
      CVV_TOOLTIP: '.checkout-billing__cvv__tooltip-wrapper',
      NEW_LABEL: '.checkout-billing__payment-selector--CREDIT_CARD',
      SAVED_LABEL: '.checkout-billing__payment-selector--SAVED_CREDIT_CARD',
      SAVED_FORM: '.checkout-billing__payment-form--SAVED_CREDIT_CARD',
      SAVED_DROPDOWN_CONTAINER: '.saved-cards__container',
      SAVED_DROPDOWN: '#savedCards',
      NUMBER_GROUP: '.checkout-billing__credit-card-number',
      DATE_GROUP: '.checkout-billing__credit-card-expiry',
      NEW_OPTION: '.checkout-billing__payment--CREDIT_CARD',
    },
    APPLE_PAY: {
      OPTION: '.checkout-billing__payment--DW_APPLE_PAY',
    },
  },
  SUBMIT: '.checkout-billing__submit-btn',
  ERRORS: {
    CONTAINER: '.checkout-step__errors',
    CONTENT: '.error-summary',
  },
  SUMMARY: '.summary',
};

export const EVENTS = {
  SAVED_ADDRESS: {
    ADDRESS_SELECTED: 'savedAddress:selected',
  },
  BILLING: {
    USE_SHIPPING_AS_BILLING_TOGGLED: 'useShippingAsBilling:toggle',
  },
  ADYENCONTENT: 'adyen3dsModalContent',
  UPDATEVIEW: 'checkout:updatedview',
  TRIGGER_SHIPPING_VALIDATION: 'checkout:shippingValidate',
};

const showComponent = (selector) => {
  const component = document.querySelector(selector);

  if (component) {
    component.classList.remove('d-none');
    scrollTo(component);
  }
};

const hideComponent = (selector) => {
  const component = document.querySelector(selector);

  if (component) {
    component.classList.add('d-none');
  }
};

const appendEmptyStage = (selector, content) => {
  const component = document.querySelector(selector);

  if (component) {
    if (content && content.length) {
      const container = document.createElement('div');
      container.innerHTML = content;
      component.appendChild(container);
    }
  }
};

const handleCreditCardExpiryErrors = (fieldErrors) => {
  const modifiedErrors = Object.assign(fieldErrors);

  const dateField = document.querySelector(SELECTORS.PAYMENT_METHODS.CREDIT_CARD.DATE);
  const monthField = document.querySelector(SELECTORS.PAYMENT_METHODS.CREDIT_CARD.MONTH);
  const yearField = document.querySelector(SELECTORS.PAYMENT_METHODS.CREDIT_CARD.YEAR);

  if (dateField && monthField && yearField) {
    const expiryErrors = Object.keys(modifiedErrors)
      .filter(name => name === monthField.name || name === yearField.name)
      .map(name => modifiedErrors[name]);

    if (expiryErrors.length) {
      modifiedErrors[dateField.name] = expiryErrors.join(' ');
    }
  }

  return modifiedErrors;
};

const processFieldErrors = fieldErrors => handleCreditCardExpiryErrors(fieldErrors);

const redirectTo = (redirectUrl) => {
  const stepForm = document.querySelector(SELECTORS.STEP_FORM);

  if (stepForm) {
    $(stepForm).spinner().start();
  }

  // Executing the redirect in the main thread can cause IE to hang when leaving
  // the site, e.g. to get to a payer auth page
  setTimeout(() => {
    window.location = redirectUrl;
  });
};

export const clientSideValidationUpdate = async () => {
  const shippingForm = document.querySelector(SELECTORS.SHIPPING_FORM);
  clientSideValidation.functions.clearForm(shippingForm, true);
};

export const reinitClientSideValidation = () => {
  processInclude(clientSideValidation);
};

export const isRedirectResponse = (content) => {
  if (content) {
    const errorUrl = content.error && content.redirectUrl;
    const paypalUrl = content.paypalProcessorResult && content.paypalProcessorResult.success && content.paypalBaseUrl;

    return content.continueUrl || paypalUrl || errorUrl;
  }

  return false;
};

/**
 * Handles backend responses that contain rendered content for checkout components
 *
 * @param {Object} content Content returned by an AJAX request
 */
export const updateView = async (content) => {
  if (content.continueUrl) {
    redirectTo(content.continueUrl);
    return;
  }

  if (content.paypalProcessorResult && content.paypalProcessorResult.success && content.paypalBaseUrl) {
    redirectTo(content.paypalBaseUrl + content.paypalProcessorResult.paypalToken);
    return;
  }

  if (content.error) {
    if (content.redirectUrl) {
      redirectTo(content.redirectUrl);
      return;
    }

    if (content.errorContent) {
      await updateComponent(SELECTORS.ERRORS.CONTENT, content.errorContent);
      showComponent(SELECTORS.ERRORS.CONTAINER);
    }

    if (content.fieldErrors) {
      if (content.fieldErrors.length) {
        content.fieldErrors.forEach((error) => {
          if (Object.keys(error).length) {
            loadFormErrors(document, processFieldErrors(error));
          }
        });
      }
    }
  } else {
    await updateComponent(SELECTORS.ERRORS.CONTENT, '');
    hideComponent(SELECTORS.ERRORS.CONTAINER);
  }

  if (content.addressSelectorContent) {
    await updateComponent(SELECTORS.SAVED_ADDRESS.SHIPMENT_SELECTOR, content.addressSelectorContent);
  }

  if (content.shippingMethodsContent) {
    await updateComponent(SELECTORS.SHIPPING_METHODS.CONTAINER, content.shippingMethodsContent);
    reinitClientSideValidation(); // Required for HubBox
  }

  if (content.giftContent) {
    await updateComponent(SELECTORS.GIFT.CONTAINER, content.giftContent);
  }

  if (content.billingAddressContent) {
    await updateComponent(SELECTORS.BILLING_ADDRESS.CARD, content.billingAddressContent);
    reinitClientSideValidation();
  }

  if (content.summaryContent) {
    await updateComponent(SELECTORS.SUMMARY, content.summaryContent);
  }

  if (content.giftCardFormContent) {
    await updateComponent(SELECTORS.PAYMENT_METHODS.GIFT_CARD_FORM, content.giftCardFormContent);
  }

  if (content.skipStepContent) {
    appendEmptyStage(SELECTORS.CONTENT, content.skipStepContent);
  } else if (content.stepContent) {
    await updateComponent(SELECTORS.CONTENT, content.stepContent);
    showComponent(SELECTORS.CONTENT);
    reinitClientSideValidation();
  }

  if (content.adyenAction) {
    document.dispatchEvent(new CustomEvent(EVENTS.ADYENCONTENT, {
      detail: {
        adyenAction: content.adyenAction,
        orderToken: content.orderToken,
      },
    }));
  }

  document.dispatchEvent(new CustomEvent(EVENTS.UPDATEVIEW, {
    detail: {
      stage: content.currentStage,
      action: content.action,
    },
  }));
};

export const attachCustomValidationEvents = () => {
  document.addEventListener(EVENTS.TRIGGER_SHIPPING_VALIDATION, () => {
    clientSideValidation.functions.validateForm(SELECTORS.SHIPPING_FORM);
  });
};
