/**
 * This is the main entry point for the application.
 */

// eslint-disable-next-line import/named
import { loadChunks, loadProjectChunks } from './initApp'; // Initialize the application
import './sfra/main'; // call the main module
/*
 * Load the main stylesheet.
 *
 * Stylesheet will be built and extracted to CSS using Webpack.
 */
import './mainStyle';

loadChunks(); // load additional needed chunks
loadProjectChunks(); // load additional needed chunks
filterCookieValue();

function contentEventInitData() {
  const element = document.querySelectorAll('.content-block-event');
  element.forEach((ele) => {
    if (ele.getAttribute('data-listener') !== 'true') {
      ele.setAttribute('data-listener', 'true');
      ele.addEventListener('click', () => {
        const indx = Array.from(document.getElementsByClassName('content-block-event')).indexOf(ele);
        const contentSlotId = `home-content-${indx + 1}`;
        const clickEventData = {
          event: 'contentBlockClick',
          contentBlockId: contentSlotId,
        };
        window.dataLayer.push(clickEventData);
      });
    }
  });
}

function filterCookieValue() {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookieItem = cookieArray[i];
    while (cookieItem.charAt(0) === ' ') {
      cookieItem = cookieItem.substring(1);
    }
    const cookiePair = cookieItem.split('=');

    // filter the cookie value to remove script tags
    if (cookiePair[1].indexOf('script') > -1) {
      const filteredValue = cookiePair[1].replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi, '');
      // eslint-disable-next-line prefer-template
      document.cookie = cookiePair[0] + '=' + filteredValue + '; path=/';
    }
  }
}

document.addEventListener('DOMContentLoaded', contentEventInitData);
document.addEventListener('product:carousel-init', contentEventInitData);
