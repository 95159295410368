import loadProjectChunksVal from './util/loadProjectChunks.val';
import appConfig from './internal/appConfig';

/**
 * Loads the needed scripts provided via the projectScripts config.
 */
export function loadProjectChunks() { // eslint-disable-line import/prefer-default-export
  const { projectScripts } = appConfig;
  if (projectScripts) {
    loadProjectChunksVal(projectScripts);
  }
}

export * from '~base';
