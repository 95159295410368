import '~base';

import $ from 'jquery';

const baseSpinner = $.fn.spinner;

if (baseSpinner) {
  $.fn.spinner = function (...args) {
    const $element = $(this);

    const fn = baseSpinner.call(this, ...args);
    const fnStop = fn && fn.stop;

    if (fnStop) {
      /**
       * The base spinner plugin attaches a 'veiled' class on the parent element
       * when starting but checks the element itself for the class when stopping
       */
      fn.stop = function () {
        const $veil = $('.veil');

        /* eslint-disable jquery/no-class, jquery/no-parent, jquery/no-css */
        if ($element.length && $veil.parent().parent().hasClass('veiled')) {
          $veil.parent().parent().css('position', '');
          $veil.parent().parent().removeClass('veiled');
        }
        /* eslint-enable */

        fnStop.call(this);
      };
    }

    return fn;
  };
}
