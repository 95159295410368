'use strict';

function handleSizeConversion(baseSize, defaultSizes, countrySizes, zeroPrefix, countryPrefix) {
  let convertedSize = baseSize;

  const numericSize = parseInt(baseSize, 10);
  const sizeIndex = defaultSizes.indexOf(numericSize);

  if (sizeIndex >= 0) {
    const currentCountrySize = countrySizes[sizeIndex];

    if (typeof currentCountrySize !== 'undefined') {
      convertedSize = currentCountrySize;

      if (zeroPrefix && convertedSize.toString().length === 1 && convertedSize.toString().length !== '0') {
        convertedSize = '0' + convertedSize;
      }
    }
  }

  if (countryPrefix) {
    convertedSize = countryPrefix + ' ' + convertedSize;
  }

  return convertedSize;
}

module.exports = {
  handleSizeConversion: handleSizeConversion,
};
