/* eslint-disable jquery/no-ajax */
/* eslint-disable jquery/no-trigger */
/* eslint-disable jquery/no-html */
/* eslint-disable jquery/no-class */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import $ from 'jquery';

const SELECTORS = {
  ALP_SIZE_LINK: '.intelligent-reach-section .size-link',
  ALP_SIZE_LINK_SPAN: '.intelligent-reach-section .size_inner_space_new',
  ALP_ADD_TO_CART: '.intelligent-reach-section .basket_btn',
};

// ALP Events
const removeSelectedClass = () => {
  const searchInputs = document.querySelectorAll('.intelligent-reach-section .size-link');

  searchInputs.forEach((searchField) => {
    searchField.classList.remove('sizeSelected');
    searchField.classList.remove('product-detail__attribute__value--current');
  });
};

export const alpProductLoad = () => {
  $(document).on('click', SELECTORS.ALP_SIZE_LINK, (e) => {
    const sizelink = e.target;
    const basketButton = document.querySelector(SELECTORS.ALP_ADD_TO_CART);
    const sizelinkObj = sizelink.classList.contains('size_inner_space_new') ? sizelink.parentElement : sizelink;

    if (!sizelinkObj.classList.contains('out_of_stock')) {
      removeSelectedClass();
      sizelinkObj.classList.add('sizeSelected');
      sizelinkObj.classList.add('product-detail__attribute__value--current');
      basketButton.dataset.pid = sizelinkObj.dataset.id;
      if ($('.intelligent-reach-section .size_new').hasClass('product-detail__attribute--error')) {
        $('.intelligent-reach-section .size_new').removeClass('product-detail__attribute--error');
        $('.product-detail__attribute__error').html('');
      }
      // basketButton.disabled = false;
    } else {
      // basketButton.disabled = true;
    }
  });

  $(document).on('click', SELECTORS.ALP_ADD_TO_CART, (e) => {
    let execute = true;
    const buttonObj = e.target;
    const searchInputs = document.querySelectorAll('.intelligent-reach-section .size-link.sizeSelected');
    if (searchInputs.length === 0) {
      $('.intelligent-reach-section .size_new').addClass('product-detail__attribute--error');
      $('.product-detail__attribute__error').html('Please select your size');
      execute = false;
    }
    if (execute) {
      const productId = buttonObj.dataset.pid;
      const qty = buttonObj.dataset.minQuantity;
      const addToCartAjexUrl = buttonObj.dataset.addToCartUrl;

      const dataObj = {
        pid: productId,
        quantity: qty,
      };

      if (addToCartAjexUrl) {
        $.ajax({
          url: addToCartAjexUrl,
          method: 'POST',
          data: dataObj,
          success: function success(data) {
            $('.minicart').trigger('count:update', data);
            $('body').trigger('product:afterAddToCart', data);
            $.spinner().stop();
          },
          error: function error() {
            $.spinner().stop();
          },
        });
      }
    }
  });
};

export default () => {
  if ($('.intelligent-reach-section').length > 0) {
    alpProductLoad();
  }
};
