// URL manipulation
// ================

const DUMMY_HOST_PROTOCOL = 'http://dummy';

const prepareURL = (url) => {
  let theURL;
  try {
    theURL = new URL(url);
  } catch (e) {
    if (url.indexOf('/') === 0) {
      theURL = new URL(url, DUMMY_HOST_PROTOCOL);
    } else {
      throw e;
    }
  }
  return theURL;
};

const prepareURLString = (url) => {
  let urlString = url.toString();
  if (urlString.indexOf(`${DUMMY_HOST_PROTOCOL}/`) === 0) {
    urlString = urlString.substring(DUMMY_HOST_PROTOCOL.length);
  }
  return urlString;
};

/**
 * Attach object data to an existing URL.
 *
 * @param url the URL
 * @param data the data to attach
 */
const attachDataToURL = (url, data) => {
  const theURL = prepareURL(url);
  const searchParams = new URLSearchParams(theURL.search);
  Object.keys(data).forEach((key) => {
    let value = data[key];
    if (value === null || value === undefined) {
      value = '';
    }
    searchParams.set(key, value);
  });
  theURL.search = searchParams.toString();
  return prepareURLString(theURL);
};

/**
 * Remove parameters from the given URL.
 *
 * @param url the URL
 * @param paramsToRemove the params to remove
 */
const removeParamsFromURL = (url, paramsToRemove) => {
  const theURL = prepareURL(url);
  const searchParams = new URLSearchParams(theURL.search);
  paramsToRemove.forEach((param) => {
    searchParams.delete(param);
  });
  theURL.search = searchParams.toString();
  return prepareURLString(theURL);
};


// Preparation of form data
// ========================

/**
 * Create a new container for form data (can be FormData or URLSearchParams) and add an object to it.
 * @param object  the object containing ghe data to add
 * @param FormDataContainer  the form data container type
 */
const objToFormDataContainer = (object, FormDataContainer) => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key]);
  return formData;
}, new FormDataContainer());

/**
 * Create a FormData object using an object.
 * @param object  the object containing ghe data to add
 */
const getFormData = object => objToFormDataContainer(object, FormData);

/**
 * Create a URLSearchParams object using an object.
 * @param object  the object containing ghe data to add
 */
const getURLSearchParams = object => objToFormDataContainer(object, URLSearchParams);


// Request preparation
// ===================

/**
 * General options for all types of fetch requests.
 */
const fetchGeneral = {
  credentials: 'same-origin',
};

/**
 * General headers for all types of fetch requests.
 */
const fetchHeadersGeneral = {
  'X-Requested-With': 'XMLHttpRequest',
};

/**
 * Generate options for fetch requests.
 * This contains general options and headers.
 * @param options   (optional) options for the request merged with the general options
 * @param additionalHeaders  (optional) additional headers for the request merged with the general headers
 */
const fetchOptionsGeneral = (options = {}, additionalHeaders = {}) => ({
  ...fetchGeneral,
  headers: {
    ...fetchHeadersGeneral,
    ...additionalHeaders,
  },
  ...options,
});

/**
 * Generate options for GET requests.
 * This contains general options and headers.
 * @param options  (optional) options for the request merged with the general options
 * @param additionalHeaders  (optional) additional headers for the request merged with the general headers
 */
const fetchOptionsGet = (options = {}, additionalHeaders = {}) => fetchOptionsGeneral({
  method: 'GET',
  ...options,
}, additionalHeaders);

/**
 * Generate options for general POST requests.
 * This contains general options and headers.
 * @param body  the body for the POST request
 * @param options  (optional) options for the request merged with the general options
 * @param additionalHeaders  (optional) additional headers for the request merged with the general headers
 */
const fetchOptionsPost = (body, options = {}, additionalHeaders = {}) => fetchOptionsGeneral({
  method: 'POST',
  body,
  ...options,
}, additionalHeaders);

// Exports
// =======

export {
  attachDataToURL,
  removeParamsFromURL,
  getFormData,
  getURLSearchParams,
  fetchOptionsGeneral,
  fetchOptionsGet,
  fetchOptionsPost,
};
