/**
 * @description Initializes the necessary interactions for Google Analytics tracking
 */

import $ from 'jquery';
import processInclude from './util';
import * as analyticsBase from './googleAnalytics/googleAnalytics';
import * as analyticsEvents from './googleAnalytics/clientSideEvents';

import '~base';

$(document).ready(() => {
  processInclude(analyticsBase);
  processInclude(analyticsEvents);
});
